<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <misc-wordpress-one />
    <misc-wordpress-two />
    <nhs-wordpress-one />
    <nhs-javascript-one />
    <nhs-javascript-two />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import MiscWordpressOne from '../../components/project-segments/misc/WordpressOne'
import MiscWordpressTwo from '../../components/project-segments/misc/WordpressTwo'
import NhsWordpressOne from '../../components/project-segments/nhs/WordpressOne'
import NhsJavascriptOne from '../../components/project-segments/nhs/JavascriptOne'
import NhsJavascriptTwo from '../../components/project-segments/nhs/JavascriptTwo'

export default {
    name: 'Wordpress',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        MiscWordpressOne, MiscWordpressTwo, NhsWordpressOne, NhsJavascriptOne, NhsJavascriptTwo
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Wordpress'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
