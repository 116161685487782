<template>
  <segment-wrapper category="magento" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>There was a base .phtml template file, within there I set a check on a new directory. 
        If there was a file with a name that matched the url (these would rarely be changed), 
        then it would display this instead of the existing content.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'MagentoTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<?php $fullWidthHeader = is_file($block->getTemplateFile('Vektor_BrandPages::category/headers/'. preg_replace('/_/', '-', $slug) . '.phtml')) ? true : false;
?>
<?php if ($fullWidthHeader) : ?>
  <?php include ($block->getTemplateFile('Vektor_BrandPages::category/headers/'. preg_replace('/_/', '-', $slug) .'.phtml'));?>
<?php ?>`
    }
  },
}
</script>
