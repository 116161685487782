<template>
  <segment-wrapper category="bulma" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="styles" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Time was inevitably of the essence, so my other design choices were worked around the UI
        provided with Bulma. I chose this framework too as it allows you to pick the javascript
        framework of choice, whilst lending a hand with more complex layouts, using easy-to-read
        class conventions.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'BulmaOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      styles: [
        {
          thumb: 'images/bathe/bulma-bathe.jpg',
          description: 'Bathe using Bulmas UI',
          height: 338,
          width: 527,
        }
      ]
    }
  },
}
</script>
