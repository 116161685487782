<template>
  <segment-wrapper category="vue" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/asahi/screen-heights.png')" @load="imagesLoaded()" alt="Viewport heights" width="320" height="323" /></p>

      <p>Using flex box meant the containers to grow to the height of the users viewport, giving it a 
        more ‘fitted’ feeling.</p>

      <p>As a stage was changed however this clashed with how Vue transitions between templates and created 
        an awkward pop as the next stage entered the DOM.</p>

      <p>To overcome this, I had set the ‘to’ stage to position: absolute, then switch this to relative 
        on a timeout function that matched the transition duration.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueFour',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<Transition name="fade">
  <template v-if="stage == 'questionairre'">
    <div class="questions" style="position: absolute">
      <c-questionairre @complete="navigateQuestionairre">
        ...
      </c-questionairre>
    </div>
  </template>
</Transition>

...

.fade-enter-active,
.fade-leave-active {
  transition: all .3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

...

created() {
  setTimeout(function() {
    document.querySelector('.questions').style.position = 'relative';
  }, 300);
}
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.asahi {
  #vue-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #asahi-vue-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>
