<template>
  <div class="image-wrap" :class="[hasBg() ? 'has-bg' : '', images.length == 1 ? 'single' : '', arrangement()]" :style="background()">
    <figure v-for="(image, id) in images" :class="image.class" :key="id">
      <img 
        :src="require( `../assets/${image.thumb}`)" 
        :width="image.width" 
        :height="image.height" 
        :alt="image.description"
        @load="imagesLoaded()"
        >
    </figure>
  </div>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'ImageLayout',

  props: {
    backgroundColor: {
      type: String,
      default: ''
    },

    backgroundImage: {
      type: String,
      default: ''
    },

    images: {
      type: Array,
    },

    arrange: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loads: 0
    }
  },

  methods: {
    imagesLoaded() {
      this.loads += 1
      if (this.loads == this.images.length) {
        this.$root.$emit('checkContainerHeight')
      }
    },

    hasBg() {
      if (this.backgroundImage != '' || this.backgroundColor != '') {
        return true
      }
    },

    arrangement() {
      if (this.images.length > 1 && this.arrange == '') {
        return 'multiline'
      } else {
        return this.arrange
      }
    },

    background() {
      if (this.backgroundImage != '' && this.backgroundColor === '') {
        let image = require('../assets/' + this.backgroundImage +'')
        return 'background-image: url(' + image + ');'
      } else if (this.backgroundColor != '' && this.backgroundImage === '') {
          return 'background-color: ' + this.backgroundColor
      } else if (this.backgroundColor != '' && this.backgroundImage != '') {
        return 'background: url(' + image + ')' + this.backgroundColor
      } else {
        return 'background-color: rgba(255,255,255,1)'
      }
    }
  }
}
</script>

<style lang="scss">
.image-wrap {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 3px 0 rgba(0,0,0,.05);

  .phone, .desktop {
    border: .25rem solid #eee;
    border-radius: 1rem;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    font-size: 0;
    max-width: 12rem;
    margin: 1rem;
    position: relative;

    img {
      border-radius: .75rem;
    }
  }

  .phone {
    max-width: 12rem;

    &::after {
      bottom: -3px;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,.2);
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .desktop {
    margin-bottom: 7%;
    max-width: 36rem;

    &::before {
      bottom: -15%;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,.2);
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::after {
      background-image: url(../assets/images/monitor-stand.png);
      bottom: calc(-20% - .25rem);
      content: '';
      height: 20%;
      background-size: contain;
      background-repeat: no-repeat;
      left: 38.5%;
      position: absolute;
      width: 23%;
    }
  }

  .browser-fs, .browser-hs {
    display: inline-block;
    border-radius: 6px;
    background-color: #fff;
    background-repeat: no-repeat;
    box-shadow: 5px 5px 15px 0px rgba(50, 50, 50, 0.2);
    font-size: 0;
    margin: 1rem;

    img {
      border-radius: 0 0 6px 6px;
      border-top: 1px solid #eee;
    }
  }

  .browser-fs {
    padding-top: 3rem;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="%23FF5F56" stroke="%23E0443E" strokeWidth=".5" /><circle cx="26" cy="6" r="6" fill="%23FFBD2E" stroke="%23DEA123" strokeWidth=".5" /><circle cx="46" cy="6" r="6" fill="%2327C93F" stroke="%231AAB29" strokeWidth=".5" /></g></svg>');
    background-position: 16px 16px;
  }

  .browser-hs {
    padding-top: 1.5rem;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="28" height="8" viewBox="0 0 28 8"><g fill="none" fillRule="evenodd" transform="translate(1 1)"><circle cx="3" cy="3" r="3" fill="%23FF5F56" stroke="%23E0443E" strokeWidth=".25" /><circle cx="13" cy="3" r="3" fill="%23FFBD2E" stroke="%23DEA123" strokeWidth=".25" /><circle cx="23" cy="3" r="3" fill="%2327C93F" stroke="%231AAB29" strokeWidth=".25" /></g></svg>');
    background-position: 8px 8px;
  }
}

.segment-content {
    img {
    width: 100%;
  }
  
  &.is-horizontal {
    .image-wrap {
      &.single {
        img {
          width: 100%;
        }
      }

      &.multiline {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        figure {
          max-width: 45%;
        }
      }

      //&.align-center {
        //align-items: center;
        //align-self: center;
      //}
    }
  }

  &.is-vertical {
    .image-wrap {
      &.multiline {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: $tablet - 1px) {

  .image-wrap {
    padding: 1rem;
    flex-wrap: wrap;

    img {
      width: 100%;
    }
  }  
}

@media screen and (min-width: $tablet) {

  .segment-content {
    .image-wrap {
      position: relative;
      padding: 2rem;
      border-radius: .5rem;

      &.overlay {
        z-index: 1;
      }
    }

    &.is-horizontal {
      .image-wrap:not(.overlay) {
        padding-right: 6.5rem;
      }
    }
    
    &.is-vertical {
      .image-wrap {
        justify-content: center;
        padding-bottom: 4.5rem;
        top: 1.5rem;

        &.multiline {
          //align-items: flex-start;
          align-items: center;

          figure {
            max-width: 48%;

            &:nth-child(2), &:nth-child(4) {
              margin-left: 4%;
            }
          }
        }

        &.align-center {
          figure {
            max-width: 45%;
          }
        }

        &.single-line-md {
          flex-wrap: nowrap;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {

  .segment-content {
    .image-wrap {
      border-radius: 1rem;
      //padding: 3rem;
      padding: calc(min(2.5vw, 3.375rem));
    }

    &.is-horizontal {
      .image-wrap:not(.overlay) {
        padding-right: calc(min(6.5vw, 6.5rem));
      }
    }

    &.is-vertical {
      .image-wrap {
        padding-bottom: calc(min(3.8vw, 5.125rem));
      }
    }
  }
}
</style>
