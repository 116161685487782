<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <!-- <div class="vimeo-wrapper">
        <div id="froggParallax"></div>
      </div> -->

      <div class="video-wrapper" style="background-image: url(@/assets/images/frogg/frogg-parallax-bg.png)">
        <video loop autoplay>
          <source src="@/assets/videos/frogg-parallax.mp4" @load="imagesLoaded()"  type="video/mp4">
        </video>
      </div>

      <p>The design featured leaf shapes which overlayed the textured background at given points.
        The customer wanted these and any other background images to feature a parallaxed effect
        when scrolling.
      </p>

      <p>Essentially what was required here was image to have the images translateY css property 
        calculated to increase or decrease at 2 times the speed of the user’s scrolltop position. 
        To keep the demand on the processor down, I used the Intersection Observer method to add 
        and remove the scroll event listeners. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'
//import Player from '@vimeo/player';

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
    //Player
  },

  data () {
    return {
      snippet: `
let parallaxItem = document.querySelectorAll('.parallax');
let scrolled = window.pageYOffset;

let observer = new IntersectionObserver(function(entries) {
  entries.forEach(function(item) {
    if (item.isIntersecting) {
      let pos = item.getBoundingClientRect().top - window.pageYOffset;
      if (pos > 0) {
        window.addEventListener('scroll', function() {
          item.target.style.transform = 'translateY(' + (window.pageYOffset * .2) + "px" + ")";
        });
      } else {
        window.removeEventListener('scroll');
      }
    }
  });
}, self.config);

parallaxItem.forEach(function(item){
  observer.observe(item);
});`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
  // created() {
  //   let script = document.createElement('script')
  //   script.async = true
  //   script.setAttribute('src', 'https://player.vimeo.com/api/player.js')
  //   document.head.appendChild(script)
  // },

  // mounted() {
  //   const player = new Player('froggParallax', {
  //     autoplay: 1,
  //     loop: 1,
  //     title: 0,
  //     byline: 0,
  //     portrait: 0,
  //     id: '770177088',
  //     controls: 0,
  // });
  // }
}
</script>
