<template>
  <segment-wrapper category="foundation" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>As there was a lot to focus on with building my own theme and content, I
          chose Zurb’s Foundation framework to build off, as this was one of the
          first on the newly released Drupal 8, and provided the necessary UI and
          structure whilst being customisable.</p>

      <p>One of my bugbears is how sidebars become squashed on tablet. My first task
        was to make sure the layout maintained structure across devices.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'FoundationOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
<div class="row">
  <div class="small-12 medium-8 large-9 columns">
    {{ content.main }}
  </div>
  <div class="small-12 medium-4 large-3 columns">
    {{ content.sidebar }}
  </div>
</div>`
    }
  }
}
</script>
