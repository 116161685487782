<template>
  <segment-wrapper category="vue" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/txpatterns/sidebar.gif')" @load="imagesLoaded()" alt="Sidebar in use" width="581" height="400" /></p>
      <p>The component structure of Vue lent itself to building re-usable assets. Having HTML,
        style and script rules all in one place made components easy to re-use and maintain.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<div class="tx-sidebar" :class="{'tx-is-open':buttonClicked}">
    <div class="tx-title-bar">
        <div>{{ title }}</div>
        <a @click="toggleClass">
            <div class="tx-icon-wrap">
                <i class="fas fa-angle-double-right"></i>
            </div>
        </a>
     </div>
    <ul>
        <li v-for="item in filteredLinks(section)" :key="item.name">
            <router-link :class="{'tx-current': page === item.name}" :to="{path: item.url}" v-tooltip="{content: item.name,  placement: 'right', classes:sidebarTooltipClass}">
                <i :class="item.icon"></i>
                <span> {{ item.name }}</span>
            </router-link>
        </li>
    </ul>
</div>`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>
