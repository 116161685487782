<template>
  <segment-wrapper category="illustrator" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="brandedItems" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Because of the sport and theme (pointer in the logo, ‘Find your ride’ strapline),
        I decided to continue this further and feature a cartograph pattern in the website
        background. This carried over onto other merchandise including Teeshirts and mugs.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'IllustratorOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      brandedItems: [
        {
          thumb: 'images/southeastbike/teeshirt-sq.jpg',
          description: 'Branded teeshirts',
          width: 239,
          height: 239
        },
        {
          thumb: 'images/southeastbike/mugs-sq.jpg',
          description: 'Branded mugs',
          width: 239,
          height: 239
        }
      ]
    }
  },
}
</script>
