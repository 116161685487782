<template>
  <segment-wrapper category="laravel">
    <template v-slot:media>
      <image-layout :images="images" arrange="match-height" />
    </template>

    <template v-slot:text>
      <p>This web app’s role was to provide a test for contractors visiting Asahi sites, the questions based on 
        a health and safety video that would have just been viewed. The questions are based in Laravel’s Nova CMS integration between 2 related content types 
        (questions and answers). The correct answers can be highlighted via a checkbox available against the answer.</p>
	
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'LaravelOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      images: [
        {
          thumb: 'images/asahi/laravel-nova-qa.png',
          description: 'Laravel Nova admin interface',
          class: 'browser-hs asahi-nova-intro',
          width: 347,
          height: 242,
        },
        {
          thumb: 'images/asahi/example-answered-question.jpg',
          description: 'Asashi Contractor example question',
          class: 'browser-hs asahi-mobile-intro',
          width: 260,
          height: 541,
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@media screen and (min-width: $tablet) {
    .asahi-nova-intro {
      width: 63.5%;
    }

    .asahi-mobile-intro {
      width: 24.5%;
    }
}
</style>
