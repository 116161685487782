<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="typography" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>With the typeface already specified, all I had to do was make the typography work in
        different screen scenarios. Some of the headings are relatively sized using the ‘golden ratio’ 
        as would compliment one another when used together. The paragraph font colour is actually 
        20% darker than $medium-gray specified, as this proved too light on some screen settings.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      typography: [
        {
          thumb: 'images/txpatterns/typography.jpg',
          description: 'Typography',
          width: 1118,
          height: 522
        }
      ]
    }
  },
}
</script>
