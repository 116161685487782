<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="homeScreens" arrange="single-line-md" />
    </template>

    <template v-slot:text>
      <p>Advanced 4 was the first website to move away from the traditional header/navbar layout.
        The full screen became the website frame, with the logo and navigation becoming part of
        the frame. The theme encouraged users to use HTML text and buttons. Prior to this they were using
        flat images, not only was text size varying wildly across devices, but it also had not
        SEO benefit.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      homeScreens: [
        {
          thumb: 'images/advanced4/home-desktop.jpg',
          description: 'Landing view on desktop',
          class: 'browser-hs',
          width: 804,
          height: 428,
        },
        {
          thumb: 'images/advanced4/home-mobile.jpg',
          description: 'Landing view on mobile',
          class: 'browser-hs',
          width: 250,
          height: 521,
        }
      ]
    }
  },
}
</script>
