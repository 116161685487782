<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The form was build to capture quotes for a removals company. The main feature was distance 
        checker between 2 input postcodes.</p>

      <p>For this I tapped into the postcodes.io API, making the call only when 2 correctly structured 
        postcodes were entered for efficiency.</p>

      <p>Once the postcode data was returned, the 2 longitude and latitudes were then entered into a 
        Javascript equivalent of the Haversine formula to produce the miles.</p>

      <p>The inputs were also repopulated. If a valid postcode was added, it returned it formatted. 
        If not it returned 'check' plus the user inputted value, which in turn triggered the frontend 
        validator and stopped the form from being submitted.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueTwo',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `

let validFormat = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

methods: {
	distanceCheck(){
    let self = this;

    const fromPostcode = (self.currentPostcode.error === '' && self.currentPostcode.model != '') ? self.currentPostcode.model : false;
    const toPostcode = (self.newPostcode.error === '' && self.newPostcode.model != '') ? self.newPostcode.model : false;

    if (fromPostcode && toPostcode) {
      const values = { postcodes: [fromPostcode, toPostcode] };
      const headers = { 'Content-Type': 'application/json;charset=UTF-8' }

      axios.post("https://api.postcodes.io/postcodes?q=", values, { 
          headers 
      }).then(function (response) {
          self.getDistance(response.data.result);
      }).catch(function (response) {
          console.log(response.message);
      });
    }
  },

  getDistance(data) {
    let self = this;

    const fromPostcodeInput = document.querySelector("#currentPostcode input");
    const toPostcodeInput = document.querySelector("#newPostcode input");
    const resultOne = data[0].result;
    const resultTwo = data[1].result;

    //Assign returned value to input for formatting and error catching
    fromPostcodeInput.value = (resultOne != null) ? resultOne.postcode : 'Check ' + self.currentPostcode.model;
    fromPostcodeInput.dispatchEvent(new Event('input'));
    toPostcodeInput.value = (resultTwo != null) ? resultTwo.postcode : 'Check ' + self.newPostcode.model;
    toPostcodeInput.dispatchEvent(new Event('input'));
    
    if (resultOne != null && resultTwo != null) {
      const oldLat = resultOne.latitude;
      const oldLong = resultOne.longitude;
      const newLat = resultTwo.latitude;
      const newLong = resultTwo.longitude;

      ... [Haversine formula] ...

      self.distance = Math.ceil(d);

      const distanceInput = document.querySelector("#distance input");
      distanceInput.value = self.distance;
      distanceInput.dispatchEvent(new Event('input'));

      self.totalUpdate();
    }
  },

  totalUpdate() {
    let self = this;
    const formItems = self.$refs.contact.field_values;
    const distanceTotal = (self.distance * self.prices.costPerMile);
    self.quote_total = roomsTotal + dwellingTotal + distanceTotal + packingPrice + unpackingPrice;
    
    self.quote_total += self.quote_total * 0.20; // add VAT

    const quoteTotalInput = document.querySelector("#quoteTotal input");
    quoteTotalInput.value = self.quote_total;
    quoteTotalInput.dispatchEvent(new Event('input'));
  }
}

mounted() {
  let self = this;
  self.currentPostcode = self.$refs.currentPostcode;
  self.newPostcode = self.$refs.newPostcode;
}

<c-input name="from_postcode" id="currentPostcode" ref="currentPostcode" v-model="form.field_values.from_postcode" placeholder="Postcode" @change="distanceCheck"></c-input>
`
    }
  }
}
</script>