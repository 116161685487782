import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router/index.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    status: 'pending',
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
    },
    clearAuth (state) {
      state.idToken = null
      state.userId = null
    },
    updateStatus(state, status) {
      Vue.set(state, 'status', status);
    },
  },
  actions: {
    login ({commit}, authData) {
      axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyDHvVzTPP89LMFYimGCIYYCSMbVwFQ5e-k', {
        email: authData.email,
        //email: 'login@designsbyben.co.uk',
        password: authData.password,
        returnSecureToken: true
      })
        .then(res => {
          localStorage.setItem('token', res.data.idToken)
          localStorage.setItem('userId', res.data.localId)
          commit('authUser', {
            token: res.data.idToken,
            userId: res.data.localId
          })
          router.push(localStorage.getItem('toRoute') || '/')
        })
        .catch(error => {
          commit('updateStatus', 'error')
        })
    },
    AutoLogin ({commit}) {
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const userId = localStorage.getItem('userId')
      commit('authUser', {
        token: token,
        userId: userId
      })
    }
  },
  getters: {
    status: state => state.status,

    user (state) {
      return state.user
    },
    ifAuthenticated (state) {
      return state.idToken !== null
    }
  }
})
