<template>
  <segment-wrapper category="wordpress" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
		<!-- <div class="vimeo-wrapper">
      <div id="peterAndPaul"></div>
		</div> -->

      <div class="video-wrapper" style="background-image: url(@/assets/images/misc/peter-paul-filter-bg.png)">
        <video loop autoplay>
          <source src="@/assets/videos/peter-and-paul.mp4"  type="video/mp4">
          <source src="@/assets/videos/peter-and-paul.ogg"  type="video/ogg">
        </video>
      </div>

	  	<p>Products are sorted by Mixitup filters, these were specified by Advanced Custom Fields set in the product admin.
			  The upgraded design specified the use of multi-select filters which required a fair amount of work.
			  The website already had code in place elsewhere for content accordions, so I tapped into this,
			  styling these to look like the select boxes that were there before.
		</p>

		<p>By using the accordion script, the user is able to make multiple selections whilst the menus remain 
			open. In the background I created an array in javascript that kept a check on each user selection. If 
			all accordions were closed, then the blurred out products would return to 'in focus'.
		</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'
//import Player from '@vimeo/player';

export default {
  name: 'WordpressTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
    //Player
  },

  data () {
    return {
      snippet: `
<?php
  if (get_field('product_type', $product->ID) != null) {
    $type = get_field('product_type', $product->ID);
  }
?>

<script>
var blurResults = function() {
  var productlistExists = (!!document.getElementsByClassName("products").length > 0);
  if(productlistExists) {
    var accordionItems = document.querySelectorAll('.accordion');
    var arr = []
    for (var i = 0; i < accordionItems.length; i++) {
      var classlist = $(accordionItems[i])[0].classList.value;

      if (classlist.indexOf("active") >= 0) {
        arr.push(true);
      } else {
        arr.push(false);
      }
    }
  }
}

if (arr.includes(true)) {
  $('.products-sort').addClass('blur');

  $(document).on("click", function(e) {
    if ($(e.target).is(".accordion") === false && $(e.target).closest(".accordion").length == 0) {
      $('.products-sort').removeClass('blur');
      $('.accordion').removeClass('active');
    }
  });
} else {
  $('.products-sort').removeClass('blur');
}

<article class="listed-product foreach ($methods as $method) { echo $method; } ?>">
`
    }
  },

  // created() {
  //   let script = document.createElement('script')
  //   script.async = true
  //   script.setAttribute('src', 'https://player.vimeo.com/api/player.js')
  //   document.head.appendChild(script)
  // },

  // mounted() {
  //   const player = new Player('peterAndPaul', {
  //     autoplay: 1,
  //     loop: 1,
  //     title: 0,
  //     byline: 0,
  //     portrait: 0,
  //     id: '721778458',
  //     controls: 0,
  // });
  // }
}
</script>
// <style lang="scss">
// .vimeo-wrapper {
// 	position: relative;
// 	overflow: hidden;
// 	pointer-events: none;
// 	padding-bottom: 60%;
// 	width: 100%;
// 	margin-bottom: 2rem;
//   box-shadow: 5px 5px 15px 0 rgba(50,50,50,.2);


// 	iframe {
// 		position: absolute;
// 		top: 0;
// 		left: 50%;
// 		transform: translateX(-50%);
// 		width: 100%;
// 		height: 100%;
// 	}
// }
// </style>
