<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Tech',
  data () {
    return {
      
    }
  }
}
</script>
