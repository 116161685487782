<template>
  <segment-wrapper category="photoshop" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="menuScreens" arrange="align-center" />
    </template>

    <template v-slot:text>
      <p>Further to this, categories were tidied into 4 main sections, with sub-categories
        hidden behind the hamburger menu.</p>

      <p>The icons against the categories helped individualise them and provided an app-like
        experience on mobile.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      menuScreens: [
        {
          thumb: 'images/southeastbike/menu-options.jpg',
          description: 'Default menu view on mobile',
          class: 'browser-hs',
          width: 232,
          height: 483
        },
        {
          thumb: 'images/southeastbike/menu-open.jpg',
          description: 'Open overlay menu',
          class: 'browser-hs',
          width: 232,
          height: 483
        }
      ]
    }
  },
}
</script>
