<template>
  <segment-wrapper category="wordpress" orientation="vertical">
    <template v-slot:media>
      <image-layout :images="images" arrange="multiline" />
    </template>

    <template v-slot:text>
      <p>This was a sub-project for an existing customer using Wordpress as a base for their online services.
		The tool had multiple routes through the questionnaire, and depending on answers provided a list of advice 
		topics would be provided at the end. The questions and advice ‘tiles’ were set up as separate content types. 
		I used Advanced Custom Fields to generate the buttons and allow the user to select which tile was called where, 
		and which question should be linked to next.</p>
	
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'WordpressOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      images: [
        {
          thumb: 'images/nhs/nhs-custom-admin-fields.jpg',
          description: 'Custom fields in Wordpress admin',
          width: 524,
          height: 323,
        },
        {
          thumb: 'images/nhs/nhs-safeguarding.jpg',
          description: 'Card design',
          width: 524,
          height: 323,
        }
      ]
    }
  },
}
</script>
