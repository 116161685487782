<template>
  <segment-wrapper category="photoshop" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="styleGuide" />
    </template>

    <template v-slot:text>
      <!-- <div v-highlight>
        <pre class="language-javascript">
          <code>
          data () {
            return {
              styleGuide: [
                {
                  filename: 'styleguide.jpg',
                  description: 'Style guide provided with mockups'
                }
              ]
            }
          },
          </code>
        </pre>
      </div> -->
      <p>In my initial considerations was the base typopgraphy, introducing weightier headings
        and a stronger typographical hierarchy, as well as making sure the base font size matched
        current web standards.</p>

      <p>Also in producing this basic style guide I established what key information the developers 
        required from the Design team when coding up bespoke layouts where full mockups were absent.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      styleGuide: [
        {
          thumb: 'images/advanced4/styleguide.jpg',
          description: 'Style guide provided with mockups',
          fullsize: 'images/advanced4/styleguide.jpg',
          width: 560,
          height: 578
        }
      ]
    }
  },
}
</script>
