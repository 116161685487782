<template>
  <div class="login" id="signin">
    <nav-bar/>
      <div class="signin-form" ref="form">
      <form @submit.prevent="onSubmit">

        <h3 :class="inputState">{{ heading }}</h3>

        <div class="field">
          <div class="control has-icons-right is-large">
            <input type="password" ref="password" id="password" @keyup="checkState(status)" class="input is-large is-fullwidth" :class="inputState" placeholder="Password" v-model="password" required autofocus>
            <span class="icon is-small is-right">
              <svg height="19" v-if="loadState === 'loading'" class="spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"/></svg>
              <svg height="18" v-else-if="inputState === 'is-danger'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"/></svg>
            </span>
            <button ref="button" id="button" class="button is-medium" :class="buttonState" type="submit">
              <svg aria-hidden="true" focusable="false" width="22" height="19" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#222" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>
            </button>
          </div>
        </div>
      </form>
    </div>
    <foot />
  </div>
</template>

<script>
  import NavBar from '../components/NavBar'
  import Foot from '../components/Foot'
  import { mapState } from 'vuex'

  export default {
    components: {
        NavBar,
        Foot,
    },

    data () {
      return {
        heading: 'Please enter password to continue',
        password: '',
        loadState: '',
        inputState: '',
        buttonState: 'hidden',
      }
    },

    created() {  
      document.title = 'Sign In'
      document.head.querySelector('meta[name=robots]').content = 'noindex'    
    },
    
    methods: {
      onSubmit () {
        console.log(this.$store.getters.user)
        this.buttonState = 'hidden'
        this.loadState = 'loading'
        const formData = {
          email: 'login@designsbyben.co.uk',
          password: this.password,
        }
        this.$store.dispatch('login', {email: formData.email,password: formData.password})
      },

      checkState(status) {
        if(password.value != '' && this.loadState == '') {
          this.buttonState = 'visible'
        } else {
          this.buttonState = 'hidden'
        }

        if (status === 'error') {
          this.heading = 'Please enter password to continue'
          this.inputState = ''
          this.$store.commit('updateStatus', 'pending')
        }
      }
    },

    computed: mapState(['status']),

    watch: {
      status(newValue, oldValue) {
        this.loadState = ''

        if (newValue === 'error') {
          this.heading = 'Please enter a valid password to continue'
          this.buttonState = 'hidden'
          this.inputState = 'is-danger'
        } else if (newValue === 'success') {
          this.inputState = 'is-success'
          this.buttonState = 'hidden'
        }
      },
    },

    mounted() {
      let input = document.querySelector('[autofocus]');
      if (input) {
          input.focus()
      }
    }
  }
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .signin-form {
    margin: auto;
    max-width: 20rem;
    width: 100%;

    form {
      margin: 1rem;
    }

    h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;

      &.is-danger {
        color: #C33243;
      }
    }

    

    .input {
      background-image: none !important;
      border: 2px solid #ddd;
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      transition: box-shadow .4s;

      &:focus, &:active {
        box-shadow: 0 0 0 4px rgba(238, 238, 238, 1);
      }

      &.is-danger {
        animation: pulse 2s 1;
        border-color: #C33243;
        box-shadow: 0 0 0 4px lighten(#C33243, 40%);
      }
    }

    .control.has-icons-right .icon.is-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    //danger #C33243; rgba(195, 50, 67,.8);
    //success #33C166 rgba(51, 193, 102, 1);

    button.button {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 2px;
      border: none;
      outline: 0;
      box-shadow: none;
      background: #eee;
      padding-top: calc(0.5em - 7px);
      padding-bottom: calc(0.5em - 7px);
      opacity: 0;
      transition: .3s opacity;

      &:hover, &:focus {
        background: #ddd;
      }

      &.visible {
        opacity: 1;
        transition: .3s opacity;
      }
    }
  }
}

.spin {
  animation: rotating .5s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
	0% {
		outline: solid 0 rgba(237, 30, 62, 0.4);
	}
	70% {
		outline: solid 15px rgba(237, 30, 62, 0);
	}
	100% {
		outline: solid 0 rgba(237, 30, 62, 0);
	}
}

@media screen and (min-width: $tablet) {
  .login {
    .signin-form {
      max-width: 28.75rem;
      
      h3 {
        font-size: 1.125rem;
      }
      
      form {
        padding: 2rem;
      }
    }
  }
}
</style>