<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="homeScreens" arrange="match-width" />
    </template>

    <template v-slot:text>
      <p>As this was the 6th website for the resource, there was plenty of history to learn from.
        A previous version had had a popular full screen slideshow on the homepage. Whilst this did
        the event photos credit, it didn’t suit the information-rich genre very well. There was a
        huge bounce rate on the homepage, as users became mesmerised with photos instead of finding
        what they had come for. In order to minimise this, the image was static on the homepage and
        had a set height, so the 2 latest news headlines could be seen above the fold. </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      homeScreens: [
        {
          thumb: 'images/southeastbike/home-desktop.jpg',
          description: 'Homepage on desktop',
          fullsize: 'images/southeastbike/home-desktop.jpg',
          class: 'browser-hs',
          width: 804,
          height: 483
        },
        {
          thumb: 'images/southeastbike/home-phone.jpg',
          description: 'Homepage on mobile',
          fullsize: 'images/southeastbike/home-phone.jpg',
          class: 'browser-hs',
          width: 250,
          height: 521
        }
      ]
    }
  },
}
</script>
