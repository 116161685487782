<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/darkstar/cta-variations.jpg')" alt="Dark Star CTA variations" width="517" height="323" /></p>

      <p>The homepage Call To Action links were one of the areas where a custom section was required.</p>

      <p>These offer a great deal of flexibility, where a background image can be set (and determine height of CTA), 
        and the foreground can have a title, subtitle, paragraph text or button set.</p> 

      <p>In addition to this the theme’s primary colour can be set as a background colour, overlaying the image if present. 
        The opacity of this can be set in the admin via a slider. The theme also used a class ‘colour-inverse’ to switch the 
        css variables to an inverted colour scheme. I gave the user access to this option also via a checkbox in the admin.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<li class="item-{{ forloop.index }} {% if block.settings.inverse_colour_scheme %} color-inverse{% endif %}">
  {%- if block.settings.link != blank -%}
    <a href="{{ block.settings.link }}">
  {%- endif -%}
    {%- if block.settings.bgImage != blank -%}
      {%- if block.settings.altMobileImage != blank -%}
        <picture>
          <source
          srcset="{{ block.settings.altMobileImage | image_url }}"
          media="(max-width: 749px)"
          >
          <source
          srcset="{{ block.settings.bgImage | image_url }}"
          media="(min-width: 750px)"
          >
      {%- endif -%}
          <img
            src="{{ block.settings.bgImage | image_url }}"
            class="bg-image"
            alt="{{ block.settings.image.alt }}"
            loading="lazy"
          >
      {%- if block.settings.altMobileImage != blank -%}
        </picture>
      {%- endif -%}
    {%- endif -%}

    {%- if block.settings.show_content_container -%}
      <style>
        .item-{{ forloop.index }} .foreground-content::after {
          opacity: {{ block.settings.container_opacity | divided_by: 100.0 }}
        }
      </style>
    {%- endif -%}

    <div class="foreground-content {% if block.settings.show_content_container %}has-container{% endif %}">
      {%- if block.settings.title != blank -%}
        <h3>{{ block.settings.title | escape }}</h3>
      {%- endif -%}
      {%- if block.settings.caption != blank -%}
        <h5>{{ block.settings.caption | escape }}</h5>
      {%- endif -%}
      {%- if block.settings.text != blank -%}
        <div>{{ block.settings.text }}</div>
      {%- endif -%}
      {%- if block.settings.link_label != blank -%}
        <div class="button{% if block.settings.button_style_secondary %} button--secondary{% else %} button--primary{% endif %}">{{ block.settings.link_label | escape }}</div>
      {%- endif -%}
    </div>
  {%- if block.settings.link != blank -%}
  </a>
  {%- endif -%}
</li>
`
    }
  },
}
</script>

<style lang="scss">
.darkstar {
  #shopify-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #darkstar-shopify-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>