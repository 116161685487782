<template>
  <segment-wrapper category="shopify" orientation="vertical">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <!-- <p><img :src="require('@/assets/images/darkstar/cta-variations.jpg')" alt="Dark Star CTA variations" width="517" height="323" /></p> -->

      <p>The design featured styling for a swatch of a product option that was out of stock. But the theme needed extending for this to work. 
        Fortunately all the product and collection data is very easily accessed in shopify so this could be achieved relatively easily.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyThree',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{% if product.variants[forloop.index0].available == false %}disabled{% endif %}
`
    }
  },
}
</script>

<style lang="scss">
.darkstar {
  #shopify-three {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #darkstar-shopify-three {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>