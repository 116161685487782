<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <div class="video-wrapper" style="background-image: url(@/assets/images/meantime/meantime-browser-desktop.jpg)">
        <video loop autoplay>
          <source src="@/assets/videos/meantime-gsap.mp4"  type="video/mp4">
        </video>
      </div>

      <p>Once the content was in place the final touch was adding some meaningful animation to the 
        content. This was achieved via GSAP and Scrolltrigger integration, animating left aligned 
        items to fade in from the right, centre aligned upwards. Groups of 'floating' elements 
        were also animated with staggered timing using an additional class. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<style>
  .animin {
    opacity: 0;
  }
</style>

<noscript>
  <style>
    .animin {
      opacity: 1;
    }
  </style>
</noscript>

{% javascript %}
if(document.querySelector('.animin')) {
    gsap.set( '.animin', { visibility: "visible", opacity: 1 });
 
    gsap.utils.toArray('.animin').forEach(function(elem) {
      if (elem.classList.contains('target-children')) {
        var targets = elem.children;
      } else {
        var targets = elem;
      }

      var tl = gsap.timeline({
        defaults: { stagger: 0.3, duration: 1.4, autoAlpha: 0 },
        scrollTrigger: { trigger: elem }
      });

      if (elem.classList.contains('fadeup')) {
        tl.from(targets, { y: 10, opacity: 0 });
      } else if (elem.classList.contains('fadeleft')) {
        tl.from(targets, { x: 10, opacity: 0 });
      } else {
        tl.from(targets, { opacity: 0 });
      }

      tl.to(targets, { x: 0, y: 0, opacity: 1, visibility: "visible" });
    });
  }
{% endjavascript %}`
    }
  }
}
</script>
