<template>
  <div class="tech-heading">
    <small>Examples of</small>
    <div>
      <!-- <img :src="require( `../assets/logos/${logo}`)" :alt="title" /> -->
      <tech-logo :logo="logo" :projectPage="false" :techPage="true" />
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
import TechLogo from '../components/TechLogo'

export default {
  name: 'TechHeading',

  components: {
      TechLogo
  },

  data () {
    return {
        title: this.$route.name,
        logo: this.$route
    }
  }
}
</script>

<style lang="scss">
.tech-heading {
  margin: 3rem auto 0;
  text-align: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: relative;
      height: 3rem;
      top: -.25rem;
    }

    h1 {
      padding-left: .5rem;
    }
  }
}
</style>
