import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store.js'
import SigninPage from '../views/SignIn'
import Home from '../views/Home'
import Tech from '../views/Tech'
import Licenses from '../views/Licenses'
import Supporters from '../views/Supporters'

import Vuejs from '../views/tech/Vue'
import Jquery from '../views/tech/Jquery'
import Javascript from '../views/tech/Javascript'
import Html from '../views/tech/Html'
import Css from '../views/tech/Css'
import Sass from '../views/tech/Sass'
import Photoshop from '../views/tech/Photoshop'
import Illustrator from '../views/tech/Illustrator'
import Git from '../views/tech/Git'
import Drupal from '../views/tech/Drupal'
import Wordpress from '../views/tech/Wordpress'
import Magento from '../views/tech/Magento'
import Foundation from '../views/tech/Foundation'
import Bootstrap from '../views/tech/Bootstrap'
import Bulma from '../views/tech/Bulma'
import Tailwind from '../views/tech/Tailwind'
import Shopify from '../views/tech/Shopify'
import Laravel from '../views/tech/Laravel'

import Advanced4 from '../views/Advanced4'
import Bathe from '../views/Bathe'
import SouthEastBike from '../views/SouthEastBike'
import VirtualSegmentation from '../views/VirtualSegmentation'
import TechnetixPatterns from '../views/TechnetixPatterns'
import Frogg from '../views/Frogg'
import Meantime from '../views/Meantime'
import DarkStar from '../views/DarkStar'
import Nhs from '../views/Nhs'
import Asahi from '../views/Asahi'

Vue.use(Router)

export default new Router({
    mode: "history",
    routes: [{
            path: '',
            name: 'Home',
            component: Home,
            meta: { transitionName: 'slide-right' },
            beforeEnter (to, from, next) {
              let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
              sessionStorage.setItem('pageCount', pageCounter + 1);
              localStorage.setItem('toRoute', to.path);
              localStorage.setItem('fromRoute', from.path);
              next()
            }
        },
        {
            path: '/tech',
            name: 'Tech', // Must remain #1 in list
            component: Tech,
            meta: { transitionName: 'slide' },
            children: [
                {
                    path: 'vue',
                    name: 'Vue', // 0
                    component: Vuejs,
                    meta: { transitionName: 'slide', name: 'vue', colour: 'green' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'jquery',
                    name: 'Jquery', // 1
                    component: Jquery,
                    meta: { transitionName: 'slide', name: 'jquery', colour: 'skyblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'javascript',
                    name: 'Javascript', // 2
                    component: Javascript,
                    meta: { transitionName: 'slide', name: 'javascript', colour: 'yellow' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'html',
                    name: 'Html', // 3
                    component: Html,
                    meta: { transitionName: 'slide', name: 'html', colour: 'orange' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'css',
                    name: 'Css', // 4
                    component: Css,
                    meta: { transitionName: 'slide', name: 'css', colour: 'duskblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'sass',
                    name: 'Sass', // 5
                    component: Sass,
                    meta: { transitionName: 'slide', name: 'sass', colour: 'pink' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'photoshop',
                    name: 'Photoshop', // 6
                    component: Photoshop,
                    meta: { transitionName: 'slide', name: 'photoshop', colour: 'duskblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'illustrator',
                    name: 'Illustrator', // 7
                    component: Illustrator,
                    meta: { transitionName: 'slide', name: 'illustrator', colour: 'orange' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'git',
                    name: 'Git', // 8
                    component: Git,
                    meta: { transitionName: 'slide', name: 'git', colour: 'orange' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'drupal',
                    name: 'Drupal', // 9
                    component: Drupal,
                    meta: { transitionName: 'slide', name: 'drupal', colour: 'midblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'wordpress',
                    name: 'Wordpress', // 10
                    component: Wordpress,
                    meta: { transitionName: 'slide', name: 'wordpress', colour: 'midblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'magento',
                    name: 'Magento', // 11
                    component: Magento,
                    meta: { transitionName: 'slide', name: 'magento', colour: 'orange' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'foundation',
                    name: 'Foundation', // 12
                    component: Foundation,
                    meta: { transitionName: 'slide', name: 'foundation', colour: 'skyblue' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'bootstrap',
                    name: 'Bootstrap', // 13
                    component: Bootstrap,
                    meta: { transitionName: 'slide', name: 'bootstrap', colour: 'purple' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                    path: 'bulma',
                    name: 'Bulma', // 14
                    component: Bulma,
                    meta: { transitionName: 'slide', name: 'bulma', colour: 'green' },
                    beforeEnter (to, from, next) {
                        let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                        sessionStorage.setItem('pageCount', pageCounter + 1);
                        localStorage.setItem('toRoute', to.path);
                        localStorage.setItem('fromRoute', from.path);
                        if (Store.state.idToken) {
                          next()
                        } 
                        else {
                          next({ path: '/signin', query: { redirect: to.path } })
                        }
                    } 
                },
                {
                  path: 'tailwind',
                  name: 'Tailwind CSS', // 15
                  component: Tailwind,
                  meta: { transitionName: 'slide', name: 'tailwindcss', colour: 'midblue' },
                  beforeEnter (to, from, next) {
                      let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                      sessionStorage.setItem('pageCount', pageCounter + 1);
                      localStorage.setItem('toRoute', to.path);
                      localStorage.setItem('fromRoute', from.path);
                      if (Store.state.idToken) {
                        next()
                      } 
                      else {
                        next({ path: '/signin', query: { redirect: to.path } })
                      }
                  } 
                },
                {
                  path: 'shopify',
                  name: 'Shopify', // 16
                  component: Shopify,
                  meta: { transitionName: 'slide', name: 'shopify', colour: 'green' },
                  beforeEnter (to, from, next) {
                      let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                      sessionStorage.setItem('pageCount', pageCounter + 1);
                      localStorage.setItem('toRoute', to.path);
                      localStorage.setItem('fromRoute', from.path);
                      if (Store.state.idToken) {
                        next()
                      } 
                      else {
                        next({ path: '/signin', query: { redirect: to.path } })
                      }
                  } 
                },
                {
                  path: 'laravel',
                  name: 'Laravel', // 17
                  component: Laravel,
                  meta: { transitionName: 'slide', name: 'laravel', colour: 'red' },
                  beforeEnter (to, from, next) {
                      let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                      sessionStorage.setItem('pageCount', pageCounter + 1);
                      localStorage.setItem('toRoute', to.path);
                      localStorage.setItem('fromRoute', from.path);
                      if (Store.state.idToken) {
                        next()
                      } 
                      else {
                        next({ path: '/signin', query: { redirect: to.path } })
                      }
                  } 
                }
            ]
        },
        {
            path: '/advanced4',
            name: 'Advanced4',
            component: Advanced4,
            meta: { readName: 'Spidersnet Advanced 4', transitionName: 'slide', tech: [6,7], themeColour: '0,0,0', locked: false, logoFileType: 'svg' },
            beforeEnter (to, from, next) {
                let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                sessionStorage.setItem('pageCount', pageCounter + 1);
                localStorage.setItem('toRoute', to.path);
                localStorage.setItem('fromRoute', from.path);
                if (Store.state.idToken) {
                  next()
                } 
                else {
                  next({ path: '/signin', query: { redirect: to.path } })
                }
            }
        },
        {
            path: '/bathe',
            name: 'Bathe',
            component: Bathe,
            meta: { readName: 'Bathe', transitionName: 'slide', tech: [1,5,6,14], themeColour: '0,0,0', locked: false, logoFileType: 'svg' },
            beforeEnter (to, from, next) {
              let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
              sessionStorage.setItem('pageCount', pageCounter + 1);
              localStorage.setItem('toRoute', to.path);
              localStorage.setItem('fromRoute', from.path);
              next()
            }
        },
        {
            path: '/southeastbike',
            name: 'SouthEast.Bike',
            component: SouthEastBike,
            meta: { readName: 'Southeast.bike', transitionName: 'slide', tech: [1,4,6,7,9,12], themeColour: '0,0,0', locked: false, logoFileType: 'svg' },
            beforeEnter (to, from, next) {
              let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
              sessionStorage.setItem('pageCount', pageCounter + 1);
              localStorage.setItem('toRoute', to.path);
              localStorage.setItem('fromRoute', from.path);
              next()
            }
        },
        {
            path: '/virtualsegmentation',
            name: 'VirtualSegmentation',
            component: VirtualSegmentation,
            meta: { readName: 'Virtual Segmentation', transitionName: 'slide', tech: [0,2,5,6,7,8,14,17], themeColour: '16, 12, 23', locked: (Store.state.idToken ? false : true), logoFileType: 'svg' },
            beforeEnter (to, from, next) {
                let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                sessionStorage.setItem('pageCount', pageCounter + 1);
                localStorage.setItem('toRoute', to.path);
                localStorage.setItem('fromRoute', from.path);
                if (Store.state.idToken) {
                  next()
                } 
                else {
                  next({ path: '/signin', query: { redirect: to.path } })
                }
            }
        },
        {
            path: '/txpatterns',
            name: 'TechnetixPatterns',
            component: TechnetixPatterns,
            meta: { readName: 'Technetix Patterns', transitionName: 'slide', tech: [0,5,6,8,14], themeColour: '11, 40, 94', locked: (Store.state.idToken ? false : true), logoFileType: 'png'  },
            beforeEnter (to, from, next) {
                let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
                sessionStorage.setItem('pageCount', pageCounter + 1);
                localStorage.setItem('toRoute', to.path);
                localStorage.setItem('fromRoute', from.path);
                if (Store.state.idToken) {
                  next()
                } 
                else {
                  next({ path: '/signin', query: { redirect: to.path } })
                }
            }
        },
        {
          path: '/frogg',
          name: 'Frogg',
          component: Frogg,
          meta: { readName: 'Frogg', transitionName: 'slide', tech: [0,2,4,5,8,17], themeColour: '5, 61, 46', locked: false, logoFileType: 'svg' },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
          path: '/meantime',
          name: 'Meantime',
          component: Meantime,
          meta: { readName: 'Meantime Brewing', transitionName: 'slide', tech: [16,2,5,8], themeColour: '0,0,0', locked: false, logoFileType: 'svg'  },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
          path: '/darkstar',
          name: 'Dark Star',
          component: DarkStar,
          meta: { readName: 'Dark Star Brewing', transitionName: 'slide', tech: [16,2,5,8], themeColour: '0,0,0', locked: false, logoFileType: 'svg' },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
          path: '/nhs-safeguarding',
          name: 'NHS Safeguarding Tool',
          component: Nhs,
          meta: { readName: 'NHS Safeguarding Tool', transitionName: 'slide', tech: [10,8,5,4,2], themeColour: '0,94,184', locked: false, logoFileType: 'svg' },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
          path: '/asahi',
          name: 'Asahi',
          component: Asahi,
          meta: { readName: 'Asahi Contractor Onboarding', transitionName: 'slide', tech: [17,8,5,2,0], themeColour: '0, 67, 145', locked: (Store.state.idToken ? false : true), logoFileType: 'svg'  },
          beforeEnter (to, from, next) {
              let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
              sessionStorage.setItem('pageCount', pageCounter + 1);
              localStorage.setItem('toRoute', to.path);
              localStorage.setItem('fromRoute', from.path);
              if (Store.state.idToken) {
                next()
              } 
              else {
                next({ path: '/signin', query: { redirect: to.path } })
              }
          }
        },
        {
          path: '/about',
          name: 'About',
          component: Licenses,
          meta: { transitionName: 'slide' },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
          path: '/local',
          name: 'Supporters',
          component: Supporters,
          meta: { transitionName: 'slide' },
          beforeEnter (to, from, next) {
            let pageCounter = parseInt(sessionStorage.getItem('pageCount'));
            sessionStorage.setItem('pageCount', pageCounter + 1);
            localStorage.setItem('toRoute', to.path);
            localStorage.setItem('fromRoute', from.path);
            next()
          }
        },
        {
            path: '/signin',
            component: SigninPage,
            meta: {transitionName: 'slide-left'}
        }
    ],

    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({ x: 0, y: 0 })
            }, 500)
        })
    },
})