<template>
  <segment-wrapper category="css" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
      <progress :style="backgroundImage()" class="progress is-info" value="15" max="100">15%</progress>
      <p>Certain updates were quite slow. I added a 'bouncing ball' to the loading bar, so the 
        user knew things were in motion in the backgound. Componentalising the code meant it 
        could be added to the pattern library and used in other projects.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'CssOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
progress[value].progress {
  background-color: $faint-gray;
  background-image: url('/images/circle.svg');
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  -webkit-animation: progressActive 1s ease-in-out infinite alternate both;
  -ms-animation: progressActive 1s ease-in-out infinite alternate both;
  animation: progressActive 1s ease-in-out infinite alternate both;
}

@keyframes progressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}`
    }
  },

  methods: {
    backgroundImage() {
      let image = require('../../../assets/images/virtualsegmentation/circle.svg')
      return 'background-image: url(' + image + ');'
    },
  }
}
</script>

<style lang="scss">
progress[value].progress {
  &::-webkit-progress-bar {
    background: transparent !important;
    max-width: 38rem;
    margin-right: auto;
    margin-left: auto;
  }
  &::-webkit-progress-value {
    transition: width 0.5s ease;
  }
  background-color: #f1f5fd;
  background-image: url('/images/virtualsegmentation/circle.svg');
  background-size:  1rem 1rem;
  background-repeat: no-repeat;
  -webkit-animation: progressActive 1s  ease-in-out infinite alternate both;
  -ms-animation: progressActive 1s  ease-in-out infinite alternate both;
  animation: progressActive 1s  ease-in-out infinite alternate both;
  &.is-small{
    background-size: .75rem .75rem;
  }
  &.is-medium{
    background-size: 1.25rem 1.25rem;
  }
}

@-webkit-keyframes progressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
@-ms-keyframes progressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
@keyframes progressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
@-webkit-keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
  </style>