<template>
    <div class="license-item">
        <ul>
            <li><strong>{{ item.name }}</strong></li>
            <li><a :href="item.link" target="_blank">{{ item.linkText }}</a></li>
        </ul>
        <ul v-for="(license, id) in item.licenses" :key="id">
            <li>{{ license.licenseType }}</li>
            <li><a :href="license.licenseUrl" target="_blank">{{ license.licenseLinkText }}</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "License",

        props: {
            data: {},
        },

        data() {
            return {
                item: this.data,
            }
        },
    }
</script>

<style lang="scss">
    .license-item {
        ul {
            list-style-type: disc;
            list-style-position: inside;
            margin-top: 1rem;
            padding-bottom: 1rem;

            &:last-child {
                border-bottom: 2px solid #eee;
            }

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                a {
                    color: #2c3e50;
                }
            }
        }

        &:last-child ul{
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
</style>
