<template>
  <segment-wrapper category="magento">
    <template v-slot:media>
      <image-layout :images="diagram" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>The Designers sent through 16 page designs to appear when a customer selected a brand.
        My first task was to work out what was common between all of them, what would be controlled
        from Magento’s admin and what would be totally custom.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'MagentoOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      diagram: [
        {
          thumb: 'images/misc/brand-page.jpg',
          description: 'split code between LESS, PHTML and XML (Magento admin).',
          fullsize: 'images/misc/brand-page.jpg',
          width: 804,
          height: 358
        }
      ]
    }
  },
}
</script>
