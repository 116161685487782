<template>
  <div class="project-page frogg">
    <nav-bar/>
    <project-heading backgroundColour="#004534">
      <template v-slot:icon>
        <img width="100%" :src="require('../assets/images/home/frogg-basic.svg')" alt="Frogg icon" />
      </template>

      <template v-slot:summary>
        <li>Dog toy manufacturer using sustainably sourced rubber</li>
        <li>Custom Javascript features including stockist listings map using Google Maps API and Parallax scrolling effects</li>
        <li>Additional Vue plugins used to extend biolerplate functionality</li>
        <li>Custom product information structure to tie components together and maximize DRY code</li>
      </template>
    </project-heading>
    
    <photoshop-one />
    <javascript-one />
    <javascript-two />
    <vue-one />
    <css-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/frogg/PhotoshopOne'
import JavascriptOne from '../components/project-segments/frogg/JavascriptOne'
import JavascriptTwo from '../components/project-segments/frogg/JavascriptTwo'
import VueOne from '../components/project-segments/frogg/VueOne.vue'
import CssOne from '../components/project-segments/frogg/CssOne.vue'


export default {
    name: 'Frogg',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, JavascriptOne, JavascriptTwo, CssOne, VueOne
    },

    created() {  
      document.title = 'Frogg'
      document.head.querySelector('meta[name=description]').content = '. Ben Wain UI Design and Development.'
    }
  }
</script>
