<template>
  <segment-wrapper category="bulma">
    <template v-slot:media>
      <image-layout :images="header" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>One small challenge with Bulma was how it chooses to put everything to the right of the
        header behind a burger menu on touch. I wanted to give mobile more consideration, and have
        the option to call and reach social channels on top level. To achieve this I had to inject
        this information twice, which I did via a php include, then switched it out with css.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'BulmaTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      header: [
        {
          thumb: 'images/bathe/header-layout.jpg',
          description: 'Header layout working with Bulma',
          height: 92,
          width: 730,
        }
      ]
    }
  },
}
</script>
