<template>
  <segment-wrapper category="sass" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Whitespace can strongly influence the feel of an interface, and providing spacing 
        dimensions that were relative to one another would mean that guttering between elements 
        and sections of content could be achieved whilst maintaining overall visual balance. 
        Naming conventions were used once again, and attributed to the measurements in the 
        SASS functions.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'SassOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
$unit: .25rem;

$spacing-data: (
  none: 0,
  unit: $unit,
  extra-tight: $unit * 2,
  tight: $unit * 4,
  base: $unit * 6,
  medium: $unit * 8,
  loose: $unit * 12,
  extra-loose: $unit * 16,
  super-size: $unit * 24
);

@function spacing($variant: base) {
  $value: map-get($spacing-data, $variant);

  @if $value == null {
    @error "Spacing variant #{$variant} not found.";
  }

  @return $value;
}`
    }
  },
}
</script>
