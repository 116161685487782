<template>
  <div v-highlight class="code-wrap">
    <pre :class="'language-' + language">
      <code>
        {{ codeSnippet }}
      </code>
    </pre>
  </div>
</template>

<script>

export default {
  name: 'CodeSnippet',

  props: {
    codeSnippet: {
      type: String,
      default: ''
    },

    language: {
      type: String,
      default: 'javascript'
    }
  }
}
</script>

<style lang="scss">
.code-wrap {
  display: flex;
  overflow-x: auto;

  pre[class*="language-"] {
    display: flex;
    flex-grow: 1;
    margin: 0;
    align-items: flex-start;
    align-self: center;

    code {
      margin: auto;

      .number {
        align-items: center;
        background-color: transparent;
        border-radius: 0;
        display: inline-flex;
        font-size: 1rem;
        height: auto;
        justify-content: center;
        margin-right: 0;
        min-width: 0;
        padding: 0;
        text-align: center;
        vertical-align: top;
      }
    }
  }
}

.segment-content {
  &.is-vertical .code-wrap {
    flex-basis: 100%;
  }

  // &.is-horizontal .code-wrap {
  //   flex-basis: 50%;
  // }
}

@media screen and (max-width: $tablet - 1px) {
  .tech-page .segment-content.is-horizontal .code-wrap {
    pre[class*="language-"] {
      border-radius: $radius $radius 0 0;
    }
  }
}

@media screen and (min-width: $tablet) {

  .segment-content {
    .code-wrap {
      pre[class*="language-"] {
        border-radius: .5rem;
      }

      &.overlay {
        position: relative;
        z-index: 1;
      }
    }

    &.is-horizontal {
      .code-wrap {
        &:not(.overlay) pre[class*="language-"] {
          padding-right: 3.5rem;
        }
      }
    }

    &.is-vertical {
      .code-wrap {
        position: relative;
        top: 1.5rem;
      }
    }
  } 
}

@media screen and (min-width: $desktop) {
  .segment-content {
    .code-wrap {
      pre[class*="language-"] {
        border-radius: 1rem;
        padding: calc(min(2.5vw, 3.375rem));
      }
    }

    &.is-horizontal {
      .code-wrap {
        &:not(.overlay) pre[class*="language-"] {
          padding-right: calc(min(2.5vw, 3.375rem));
        }
      }
    }
    
    &.is-vertical {
      .code-wrap pre[class*="language-"] {
        padding-bottom: calc(min(3.8vw, 5.125rem));
      }
    }
  }
}
</style>
