<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <darkstar-shopify-one />  
    <darkstar-shopify-two />
    <darkstar-shopify-three />  
    <meantime-shopify-two />
    <meantime-shopify-three />
    <meantime-shopify-four />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import DarkstarShopifyOne from '../../components/project-segments/darkstar/ShopifyOne'
import DarkstarShopifyTwo from '../../components/project-segments/darkstar/ShopifyTwo'
import DarkstarShopifyThree from '../../components/project-segments/darkstar/ShopifyThree'

import MeantimeShopifyTwo from '../../components/project-segments/meantime/ShopifyTwo'
import MeantimeShopifyThree from '../../components/project-segments/meantime/ShopifyThree'
import MeantimeShopifyFour from '../../components/project-segments/meantime/ShopifyFour'

export default {
    name: 'Sass',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        DarkstarShopifyOne, DarkstarShopifyTwo, DarkstarShopifyThree, MeantimeShopifyTwo, MeantimeShopifyThree, MeantimeShopifyFour
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Shopify'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
