<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <misc-jquery-one />
    <bathe-jquery-one />
    <bathe-jquery-three />
    <southeastbike-jquery-one />
    <misc-wordpress-two />
    

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import MiscJqueryOne from '../../components/project-segments/misc/JqueryOne'
import BatheJqueryOne from '../../components/project-segments/bathe/JqueryOne'
import BatheJqueryThree from '../../components/project-segments/bathe/JqueryThree'
import SoutheastbikeJqueryOne from '../../components/project-segments/southeastbike/JqueryOne'
import MiscWordpressTwo from '../../components/project-segments/misc/WordpressTwo'

export default {
    name: 'Jquery',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        MiscJqueryOne, BatheJqueryOne, BatheJqueryThree, SoutheastbikeJqueryOne, MiscWordpressTwo
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Jquery'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
