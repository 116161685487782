<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>This also meant provided answers can’t simply be compared to a single correct answers array, as 
        different incorrect answers could be from the same or different questions.</p>

      <p>Array comparison was therefore on a question-by-question basis.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
complete() {
  let self = this;
  let counter = 0;

  for (let i = 0; i < self.questions.length; i++) {
    let correct_answers = [];

    let submitted_answers = sessionStorage.getItem('question-' + i);
    submitted_answers = submitted_answers.split(',');
    submitted_answers = submitted_answers.sort();

    self.questions[i].answers.forEach( function(value, index){
      if (value.answer_is_correct) {
        correct_answers.push(value.id);
      }
    });

    if (submitted_answers.toString() == correct_answers.toString()) {
      counter ++;
    }
  }

  const score_percent = counter / self.questions.length * 100;
  sessionStorage.setItem('score_precent', score_percent);
  self.$emit('complete');
},
`
    }
  }
}
</script>
