<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <misc-sass-one />
    <txpatterns-sass-one />
    <meantime-sass-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import MiscSassOne from '../../components/project-segments/misc/SassOne'
import TxpatternsSassOne from '../../components/project-segments/txpatterns/SassOne'
import MeantimeSassOne from '../../components/project-segments/meantime/SassOne'

export default {
    name: 'Sass',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        MiscSassOne, TxpatternsSassOne, MeantimeSassOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'SASS'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
