<template>
  <segment-wrapper category="git" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="styleGuide" />
    </template>

    <template v-slot:text>
      <p>The project with all dependencies was installed using through node modules as
        thought this worked best with version control. The boss wanted this to be a simple
        FTP site on completion, so I stripped out the JavaScript node dependencies and
        added these statically, referencing CDN’s where necessary.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'GitOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      styleGuide: [
        {
          filename: 'styleguide.jpg',
          description: 'Style guide provided with mockups'
        }
      ]
    }
  },
}
</script>
