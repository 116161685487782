<template>
  <segment-wrapper category="drupal" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="news" backgroundColor="#252525" />
    </template>

    <template v-slot:text>
      <p>Drupal’s flexibility allowed the use of more interesting layouts. The news page
        used 2 main views: The first news item was filtered on its own, then the second
        onwards were displayed in a list. This allowed differences in the styling between
        the 2, helping maintain user interest.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'DrupalTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      news: [
        {
          thumb: 'images/southeastbike/news.jpg',
          description: 'News feed column',
          width: 527,
          height: 423
        },
      ]
    }
  },
}
</script>
