<template>
  <segment-wrapper category="vue" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/asahi/pass-fail-screens.png')" alt="Pass/fail screens" width="320" height="323" /></p>

      <p>Following the completion of the test and results calculation, the figures are emitted from the 
        questionnaire component and the results stage displayed.</p>

      <p>At this point the result is stored against the user’s record, and the session storage removed.</p>

      <p>This is so a user cannot refresh and retake the test. If a user tries to visit the /questionnaire url 
        directly or refresh without session storage they will be redirected to the registration page.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueThree',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
navigateQuestionairre(userId) {
  let self = this;
  const storedUser = sessionStorage.getItem('user_id');

  if (self.stage == 'landing-screen') {
    if (typeof(userId) != 'undefined') { // capture user id on 1st pass only
      sessionStorage.setItem('user_id', userId);
      self.stage = 'questionairre';
    } else if (storedUser != null) { // refresh during test
      self.stage = 'questionairre';
    } else { // prevent attempted direct access to test without registration
      window.location.href = '/';
    }
        
    } else if (self.stage == 'questionairre') {
      const user_id = sessionStorage.getItem('user_id');
      const score_percent = sessionStorage.getItem('score_precent');
      self.final_score = score_percent + '<span>%</span>';

      if (score_percent >= self.pass_threshold) {
        self.is_pass = true;
        self.result_heading = 'You’ve passed!';
        self.result_text = 'Thank you, you have passed your contractor onboarding. Great job.';
      } else {
        self.is_pass = false;
        self.result_heading = 'You’ve failed';
        self.result_text = 'Sorry, you have failed your contractor onboarding. Please speak to your Asahi contact.';
      }

      _storage.post(_config.get('api.users.store'), function (_response) {
        if (_storage.isSuccess(_response)) {
          let response = _storage.getResponseData(_response);
        }
      }, {
        data: {
          'user_id': user_id,
          'score_percent': score_percent
        },
      });

      self.stage = 'result';
      sessionStorage.clear();
    }
  window.scrollTo(0, 0);
},
`
    }
  }
}
</script>

<style lang="scss">
.asahi {
  #vue-three {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #asahi-vue-three {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>
