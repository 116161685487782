<template>
  <segment-wrapper category="drupal">
    <template v-slot:media>
      <image-layout :images="custom" arrange="single-line-md" />
    </template>

    <template v-slot:text>
      <p>Alongside Context, Views played a large part in the organisation and displaying of
        information. To keep code to a minimum I turned off the default displays, adding the
        components of the view as tokens via a Custom Text field. This way only the necessary
        markup was used. This version of Drupal also allowed Page Managers to work entirely
        from the frontend, customising their profile pages inline and adding news via the main
        web interface. Thanks to the levels of customisation within Drupal, I was able to meet
        the requirements of the new site, making it useable, minimal, clean and quick, not
        reflecting the true size of the platform behind it.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'DrupalThree',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      custom: [
        // {
        //   thumb: 'images/southeastbike/views.jpg',
        //   description: 'Customising Views for minimal code',
        //   //width: 527,
        //   //height: 423
        // },
        {
          thumb: 'images/southeastbike/profile.jpg',
          description: 'Edit Profile page for Page Managers',
          class: 'browser-hs',
          //width: 527,
          //height: 423
        },
        {
          thumb: 'images/southeastbike/add-news.jpg',
          description: 'Add News page',
          class: 'browser-hs',
          //width: 527,
          //height: 423
        }
      ]
    }
  },
}
</script>