<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="pageScreens" arrange="single-line-md" />
    </template>

    <template v-slot:text>
      <p>I was keen to make this site as responsive as possible - not just stuffing
        everything onto its own line on mobile but giving it more thought, plus giving
        sufficient button spacing for touch screens. HD viewing treats users to spread
        controls and supersized headings.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopThree',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      pageScreens: [
        {
          thumb: 'images/bathe/our-promise-desktop.jpg',
          description: 'Golden ratio on desktop',
          fullsize: 'images/bathe/our-promise.jpg',
          class: 'browser-hs',
          width: 665,
          height: 400
        },
        {
          thumb: 'images/bathe/services-mobile.jpg',
          description: 'Services layout on mobile',
          class: 'browser-hs',
          width: 179,
          height: 438
        },
        {
          thumb: 'images/bathe/our-promise-mobile.jpg',
          description: 'Testimonials on mobile',
          class: 'browser-hs',
          width: 179,
          height: 431
        }
      ]
    }
  },
}
</script>
