<template>
  <segment-wrapper category="bulma">
    <template v-slot:media>
      <image-layout :images="ui" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Form elements inherited some of their styling from Bulma's UI kit as this became our 
        framework of choice for a number of projects. Bulma, being a pure css framework allowed 
        us the flexibility to create custom components in Vue.js without conflict from 
        integrated Javacript libraries found in other frameworks. Form inputs, as well as a number 
        of other clickable elements were given a subtle 1 pixel border, as I felt this stood in 
        contrast to the otherwise 'flat' style interface, helping to enforce the metaphor these 
        elements were clickable.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'BulmaOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      ui: [
        {
          thumb: 'images/txpatterns/form-controls.jpg',
          description: 'Form control UI',
          width: 1118,
          height: 741
        }
      ]
    }
  },
}
</script>
