<template>
  <segment-wrapper category="laravel" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The company used a Wordpress website for B2C and a Laravel installation for the B2B facing elements.</p>

      <p>The top navigation was to be mirrored across between the two sites. As they were on the same server,
        I was able to import the data as a raw SQL statement in the helper file, then build the menu using Laravel's
        shorthand PHP syntax.
      </p>

      <p>I kept the class naming conventions similar to Wordpress' as this would help with future style/functionality changes.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'LaravelOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
<nav class="menu-navigation-container">
    <ul id="menu-navigation" class="menu">
        @php
        $menu = App\Helpers\Helper::get_menu_items();
        $parentMenuItems = [];
        $submenuItem = 0;
        @endphp

        @foreach ($menu["menu_items"] as $item)
            @if ($item->menu_parent != 0)
                @php $parentMenuItems[] = $item->menu_parent; @endphp
            @endif
        @endforeach

        @foreach ($menu["menu_items"] as $index=>$item)
            @if ($submenuItem != $item->menu_parent && $submenuItem != 0)
                </ul>
            @endif

            @if ($index > 0)
                </li>
            @endif

            @if (in_array($item->ID, $parentMenuItems))
                <li class="menu-item menu-item-has-children {{ $currentPage }}">
                    <a>{{ $item->item_name }}</a>
                    <ul class="sub-menu">
            @else
                <li class="menu-item {{ $currentPage }}">
                    <a href="https://greenwoodplants.co.uk/{{ $item->item_url }}">{{ $item->item_name }}</a>
            @endif
            @php $submenuItem = $item->menu_parent; @endphp
        
        @endforeach
    </ul>
</nav>
`
    }
  }
}
</script>