<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <misc-magento-one />
    <misc-magento-two />
    <misc-magento-three />
    <misc-magento-four />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import SegmentWrapper from '../../components/SegmentWrapper'
import Foot from '../../components/Foot'

import MiscMagentoOne from '../../components/project-segments/misc/MagentoOne'
import MiscMagentoTwo from '../../components/project-segments/misc/MagentoTwo'
import MiscMagentoThree from '../../components/project-segments/misc/MagentoThree'
import MiscMagentoFour from '../../components/project-segments/misc/MagentoFour'

export default {
    name: 'Magento',

    components: {
        NavBar, TechHeading, TechLogo, SegmentWrapper, Foot,
        MiscMagentoOne, MiscMagentoTwo, MiscMagentoThree, MiscMagentoFour
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Magento'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
