<template>
  <segment-wrapper category="shopify" orientation="vertical">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Shopify uses an efficient CSS structure. Many of the theme styles can be set in the site 
        schema and accessed through CSS variables. Following that individual stylesheets are 
        called in the top of sections and blocks so only what's needed is called. My first task was 
        to extend the theme and section functionality by adding the option to add a 
        class in the schema, plus set a class for each page based on the template name. A lot of the 
        layout features from the design could be created by adapting existing sections. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{% if template == 'index' %}
<link rel="stylesheet" href="{{ 'home.css' | asset_url }}" media="print" onload="this.media='all'">
{% elsif  template contains 'page' %}
<link rel="stylesheet" href="{{ 'cms-page.css' | asset_url }}" media="print" onload="this.media='all'">
{% endif %}

<body class="page-{%- if template == 'index' -%}home {%- elsif template == 'product' -%}product {%- else -%}{{ page_title | handleize }}{%- endif -%}">

`
    }
  },
}
</script>
