<template>
  <div class="heading-wrap" :class="defaultBg()">
    <div class="page-heading">
      <div class="intro">
        <div class="image-wrap">
          <slot name="icon"></slot>
        </div>

        <h1>{{ currentRouteName }}</h1>

        <div class="tags">
          <tech-logo v-for="(item, id) in techStack" :logo="itemCheck(item)" :key="id" />
        </div>

        <div class="project-summary">
          <ul>
            <slot name="summary"></slot>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TechLogo from '../components/TechLogo'

export default {
  name: 'ProjectHeading',

  components: {
    TechLogo
  },

  data () {
      return {
          items: this.$router.options.routes[1].children,
      }
  },

  methods: {
    itemCheck(id) {
       return this.$router.options.routes[1].children[id]
    },

    defaultBg() {
      if (this.backgroundColour == '') {
        return 'purple-bg'
      }
    }
  },

  computed: {
    techStack() {
      return this.$route.meta.tech;
    },

    currentRouteName() {
        return this.$route.meta.readName;
    }
  } 
}
</script>

<style lang="scss">
.page-heading {
  border-radius: $radius;
  display: flex;
  margin: 0 auto;
  max-width: 74.13rem;

  .image-wrap {
    box-shadow: none;
    margin-right: auto;
    margin-left: auto;
    width: 8rem;
  }

  h1 {
    //color: $white;
    padding: 0 1rem;
    text-align: center;
  }
  
  .tags {
    align-items: flex-start;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    padding: 0;
  }

  .tag {
    box-shadow: 0 0 0 0 #eee;
    border: 1px solid #ddd;
    background: white;
    transition: .3s box-shadow;

    &:hover {
      box-shadow: 0 0 0 3px #eee;
    }
  }

  .project-summary {
    //color: $white;
    padding: 1rem 0 0;

    ul {
    list-style-type: disc;
    list-style-position: inside;
    margin: 0 auto;

    li {
        list-style-position: outside;
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .page-heading {
    margin-right: 1rem;
    margin-left: 1rem;

    .image-wrap {
      margin-top: 2rem;
    }
  }
}

@media screen and (min-width: $tablet) {

  .page-heading {
    max-width: 61rem;

    .image-wrap {
      margin-top: 2rem;
    }

    h1 {
      padding-right: 2rem;
      padding-left: 2rem;
      padding-top: 1.5rem;
    }
    .tags {
      padding: 0 2rem;
    }

    .project-summary {
      padding-right: 2rem;
      padding-left: 2rem;

      ul {
        column-count: 2;
        column-gap: 2rem;
      }
    }
  }
}

@media screen and (max-width: $desktop - 1px) {
  .page-heading {
    margin-bottom: 2rem;

    .project-summary {
      margin-right: 1rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .page-heading {
    padding-top: 2vw;
    //padding-bottom: 2vw;

    .image-wrap {
      justify-content: center;
      border-radius: $radius;
      display: flex;
      height: auto;
      margin-top: 4rem;
      box-shadow: none;
      
      img {
        max-height: 80%;
      }
    }

    .project-summary {
      li {
        font-size: 1.125rem;
      }
    }
  }
}

@media screen and (min-width: $fullhd) {
  .page-heading {
    max-width: calc(min(52.1vw, 85.938rem));
  }
}

@media screen and (min-width: 1921px) {
  .page-heading {
    .project-summary {
      font-size: 1.25rem;
    }
    .image-wrap {
      width: 42rem;
    }
  }
}
</style>
