<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="link" backgroundColor="#D9E1E3" />
    </template>

    <template v-slot:text>
      <p>The structure of the hardware was paired into sets of transmitters and receivers. As 
        it was felt best to display the data in tables, I created the tables to show gaps in the 
        appropriate places, so a table would actually represent the physical structure of a VS 
        'Link' (a group of transmitters/recievers) on its side.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      link: [
        {
          thumb: 'images/virtualsegmentation/link-photo.jpg',
          description: 'Virtual Segmentation Hardware',
          width: 537,
          height: 358
        },
        {
          thumb: 'images/virtualsegmentation/table-layout.png',
          description: 'Table representation of the hardware Link',
          width: 468,
          height: 312
        }
      ]
    }
  },
}
</script>
