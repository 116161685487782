<template>
  <segment-wrapper category="css">
   	<template v-slot:media>
      <image-layout :images="carousel" />
    </template>

    <template v-slot:text>
      <p>The carousel received a few tweaks to match the design. To make the previous and next 
		  buttons appear as chevrons, I removed the text, set their transparency, width and 
		  height, rotated them 45 degerees and gave them right/bottom top/left borders as appropriate.
		  For touch devices these would look too large if they were to be the recommended 42px 
		  dimensions, so I set the borders on an ::after pseudo element. The item borders were 
		  not to show either end of carousel either. I resolved this by setting 
		  negative margins on an inner container whilst setting overflow: hidden on the parent.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'CssOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      carousel: [
        {
          thumb: 'images/frogg/frogg-carousel.jpg',
          description: 'making design usable on touch devices.',
          fullsize: 'images/frogg/frogg-carousel.jpg',
          width: 730,
          height: 331
        }
      ]
    }
  },
}
</script>
<style lang="scss">
  .frogg {
    #css-one {
      img {
        border-radius: 3px;
      }
    }
  }
</style>