<template>
  <segment-wrapper category="html" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Example use of semantic markup, helping to make code lighter and easier to read for both humans and browsers/bots alike.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'HtmlOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<body>
  <header></header>
  <nav></nav>
     
  <hgroup>
    <h1></h1>
    <h2></h2>
  </hgroup>

  <section>
    <article>
      <figure>
        <img>
        <figcaption></figcaption>
      </figure>
    </article>
     
    <aside>
      <section></section>
      <section></section>
    </aside>
  </section>
     
  <footer></footer>
</body>`
    }
  },
}
</script>
