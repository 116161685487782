<template>
  <div class="project-page supporters">
    <nav-bar/>
    <div class="list">
      <h1>Local Support</h1>
      <p class="intro">The following is a list of local businesses who have kindly displayed my information 
        in exchange for a link on this page. Would you like to get a little search engine boost for your
        website and exchange a link? Get in touch via my email: mail@benwain.info</p>
      <listing v-for="(listing, id) in listings" :key="id" :data="listing" />
    </div> 
    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import Listing from '../components/LocalListing'
import Foot from '../components/Foot'

export default {
    name: 'Supporters',

    components: {
        NavBar, Listing, Foot,
    },

    created() {  
      document.title = 'Local Support'  
    },

    data() {
      return {
        listings: [
          {
            name: 'Bulma',
            link: 'https://github.com/jgthms/bulma',
            linkText: 'github.com/jgthms/bulma',
          },
          {
            name: 'Bulma',
            link: 'https://github.com/jgthms/bulma',
            linkText: 'github.com/jgthms/bulma',
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
.supporters {
  .list {
    background: #fff;
    border-radius: $radius;
    margin: 2rem auto;
    max-width: 800px;

    h1 {
      text-align: center;
    }

    .intro {
      padding-bottom: 1rem;
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .list {
    padding: 1rem;
  }
}

@media screen and (min-width: $tablet) {
  .supporters {
    .list {
      padding: 1rem 2rem;

      h1 {
        padding-bottom: 1rem;
      }

      .intro {
        column-count: 2;
        column-gap: 2rem;
      }
    }
  }
}
</style>
