<template>
  <div class="project-page southeastbike">
    <nav-bar/>
    <project-heading backgroundColour="#222">
      <template v-slot:icon>
        <img :src="require('../assets/images/home/seb.png')" alt="Southeast.bike icon" />
      </template>

      <template v-slot:summary>
        <li>Freelance Project run from 2007 to 2020</li>
        <li>Popular niche resource gaining over 100 unique visits per day</li>
        <li>6th website, going from static, to Wordpress to Drupal</li>
        <li>Over time it has hosted forums and newsletters, as well as active social media feeds</li>
        <li>Events attended media accredited, publishing reports, photo galleries and video edits</li>
        <li>4 full length DVD’s produced, photos published in magazines and books</li>
      </template>
    </project-heading>
    
    <photoshop-one />
    <photoshop-two />
    <jquery-one />
    <drupal-one />
    <illustrator-one />
    <illustrator-two />
    <foundation-one />
    <drupal-two />
    <drupal-three />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/southeastbike/PhotoshopOne'
import PhotoshopTwo from '../components/project-segments/southeastbike/PhotoshopTwo'
import JqueryOne from '../components/project-segments/southeastbike/JqueryOne'
import DrupalOne from '../components/project-segments/southeastbike/DrupalOne'
import IllustratorOne from '../components/project-segments/southeastbike/IllustratorOne'
import IllustratorTwo from '../components/project-segments/southeastbike/IllustratorTwo'
import FoundationOne from '../components/project-segments/southeastbike/FoundationOne'
import DrupalTwo from '../components/project-segments/southeastbike/DrupalTwo'
import DrupalThree from '../components/project-segments/southeastbike/DrupalThree'

export default {
    name: 'Southeastbike',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, PhotoshopTwo, JqueryOne, DrupalOne, IllustratorOne, IllustratorTwo, FoundationOne, DrupalTwo, DrupalThree
    },

    created() {  
      document.title = 'Southeast.Bike'
      document.head.querySelector('meta[name=description]').content = 'Mountain Biking resource design and development by Ben Wain.'
      document.head.querySelector('meta[name=robots]').content = 'all'
    }
  }
</script>