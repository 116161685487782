<template>
  <segment-wrapper category="css" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
        <a class="tx-nav-button">
            <div class="tx-dashboard-icon">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path></svg>
            </div>
            <span>Alarm State</span>
        </a>

        <a class="tx-nav-button tx-is-alarm">
            <div class="tx-dashboard-icon">
              <span title="Badge top right" class="badge">8</span>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path></svg>
            </div>
            <span>Alarm State</span>
        </a>
      <p>Dashboard buttons could have classes appended to change 'state', which lead to a colour change,
        a badge counter being appended and CSS animations. There was also 'Warning' state, which was 
        yellow and did not pulsate.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'CssOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
.tx-is-alarm .tx-dashboard-icon {
	box-shadow: 0 0 0 rgba(237, 30, 62, 0.4);
	animation: pulse 2s infinite;
	animation-delay: 0.7s;
}

.tx-is-alarm .tx-dashboard-icon svg {
	animation: ring 2s 0.7s ease-in-out infinite;
	transform-origin: 50% 4px;
}

@keyframes pulse {
	0% {box-shadow: 0 0 0 0 rgba(237, 30, 62, 0.4);}
	70% {box-shadow: 0 0 0 10px rgba(237, 30, 62, 0);}
	100% {box-shadow: 0 0 0 0 rgba(237, 30, 62, 0);}
}

@keyframes ring {
	1% {transform: rotateZ(-11deg);}
	5% {transform: rotateZ(10deg);}
	9% {transform: rotateZ(-9deg);}
	13% {transform: rotateZ(8deg);}
	17% {transform: rotateZ(-7deg);}
	21% {transform: rotateZ(6deg);}
	25% {transform: rotateZ(-5deg);}
	29% {transform: rotateZ(4deg);}
	33% {transform: rotateZ(-3deg);}
	37% {transform: rotateZ(2deg);}
	39% {transform: rotateZ(-1deg);}
	41% {transform: rotateZ(1deg);}
	43% {transform: rotateZ(0);}
	100% {transform: rotateZ(0);}
}`
    }
  },
}
</script>

<style lang="scss">
//Bell Ringing

.tx-is-alarm .tx-dashboard-icon svg {
	-webkit-animation: ring 2s 0.7s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-moz-animation: ring 2s 0.7s ease-in-out infinite;
	-moz-transform-origin: 50% 4px;
	animation: ring 2s 0.7s ease-in-out infinite;
	transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
	1% {
		-webkit-transform: rotateZ(-11deg);
	}
	5% {
		-webkit-transform: rotateZ(10deg);
	}
	9% {
		-webkit-transform: rotateZ(-9deg);
	}
	13% {
		-webkit-transform: rotateZ(8deg);
	}
	17% {
		-webkit-transform: rotateZ(-7deg);
	}
	21% {
		-webkit-transform: rotateZ(6deg);
	}
	25% {
		-webkit-transform: rotateZ(-5deg);
	}
	29% {
		-webkit-transform: rotateZ(4deg);
	}
	33% {
		-webkit-transform: rotateZ(-3deg);
	}
	37% {
		-webkit-transform: rotateZ(2deg);
	}
	39% {
		-webkit-transform: rotateZ(-1deg);
	}
	41% {
		-webkit-transform: rotateZ(1deg);
	}
	43% {
		-webkit-transform: rotateZ(0);
	}
	100% {
		-webkit-transform: rotateZ(0);
	}
}

@-moz-keyframes ring {
	1% {
		-moz-transform: rotateZ(-11deg);
	}
	5% {
		-moz-transform: rotateZ(10deg);
	}
	9% {
		-moz-transform: rotateZ(-9deg);
	}
	13% {
		-moz-transform: rotateZ(8deg);
	}
	17% {
		-moz-transform: rotateZ(-7deg);
	}
	21% {
		-moz-transform: rotateZ(6deg);
	}
	25% {
		-moz-transform: rotateZ(-5deg);
	}
	29% {
		-moz-transform: rotateZ(4deg);
	}
	33% {
		-moz-transform: rotateZ(-3deg);
	}
	37% {
		-moz-transform: rotateZ(2deg);
	}
	39% {
		-moz-transform: rotateZ(-1deg);
	}
	41% {
		-moz-transform: rotateZ(1deg);
	}
	43% {
		-moz-transform: rotateZ(0);
	}
	100% {
		-moz-transform: rotateZ(0);
	}
}

@keyframes ring {
	1% {
		transform: rotateZ(-11deg);
	}
	5% {
		transform: rotateZ(10deg);
	}
	9% {
		transform: rotateZ(-9deg);
	}
	13% {
		transform: rotateZ(8deg);
	}
	17% {
		transform: rotateZ(-7deg);
	}
	21% {
		transform: rotateZ(6deg);
	}
	25% {
		transform: rotateZ(-5deg);
	}
	29% {
		transform: rotateZ(4deg);
	}
	33% {
		transform: rotateZ(-3deg);
	}
	37% {
		transform: rotateZ(2deg);
	}
	39% {
		transform: rotateZ(-1deg);
	}
	41% {
		transform: rotateZ(1deg);
	}
	43% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(0);
	}
}

//Pulsating:

.tx-is-alarm .tx-dashboard-icon,
.tx-status .tx-error {
	box-shadow: 0 0 0 rgba(237, 30, 62, 0.4);
	animation: pulse 2s infinite;
	animation-delay: 0.7s;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(237, 30, 62, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(237, 30, 62, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(237, 30, 62, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(237, 30, 62, 0.4);
		box-shadow: 0 0 0 0 rgba(237, 30, 62, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(237, 30, 62, 0);
		box-shadow: 0 0 0 10px rgba(237, 30, 62, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(237, 30, 62, 0);
		box-shadow: 0 0 0 0 rgba(237, 30, 62, 0);
	}
}

.tx-dashboard-icon {
	display: flex;
	align-items: center;
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	border-width: 2px;
	border-style: solid;
	position: relative;

	svg {
		fill: #009fdf;
		font-size: 1.375rem;
		line-height: 3.688rem;
		margin: auto;
	}

	.badge {
		//transform: translate(15%, -5%);
		position: absolute;
		top: 0;
		right: 0;
		justify-content: center;
		display: flex;
		align-items: center;
		font-size: 0.75rem;
		font-weight: bold;
		min-height: 1.25rem;
		min-width: 1.25rem;
		border-radius: 50%;
		color: white;
		background: #ed1e3e;
	}
}

a.tx-nav-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: white;
	width: 100%;
	padding: 1.5rem;
	border-radius: 3px;
	box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.2);
	transition: 0.3s;
	border-bottom: 2px solid #009fdf;
	margin-bottom: 1.5rem;

	span {
		display: block;
		color: #84858a;
		text-align: center;
	}
	.tx-dashboard-icon {
		border-color: #009fdf;
		svg {
			fill: #009fdf;
		}
	}
	&:hover {
		background: #009fdf;
		box-shadow: 0 0 20px 0 rgba(100, 100, 100, 0.2);
		.tx-dashboard-icon {
			border-color: white !important;
		}
		span{
			color: white !important;
		}

		svg {
			fill: white !important;
		}
	}
	&.tx-is-alarm {
		border-color: #ed1e3e;
		svg {
			fill: #ed1e3e;
		}
		.tx-dashboard-icon {
			border-color: #ed1e3e;
			.badge {
				background: #ed1e3e;
			}
		}
		&:hover {
			background-color: #ed1e3e;
			.tx-dashboard-icon .badge {
				background: white;
				color: #ed1e3e !important;
			}
		}
	}
}

@media screen and (min-width: $tablet) {
	.tx-dashboard-icon {
		flex-shrink: 0;
	}
}

@media screen and (max-width: $desktop - 1px) {
	.tx-dashboard-icon {
		svg {
			width: 1.25rem;
		}
	}
}

@media screen and (min-width: $desktop) {
	.tx-dashboard-icon {
		width: 4.688rem;
		height: 4.688rem;
		border-width: 3px;
		margin-bottom: 1rem;

		svg {
			width: 1.625rem;
		}
	}
	a.tx-nav-button span {
		font-size: 1.125rem;
	}
	.badge {
		transform: translate(0, 0);
	}
}
@media screen and (min-width: $fullhd) {
	a.tx-nav-button {
		padding: 2rem;
		span {
			font-size: 1.25rem;
		}
	}
}

</style>