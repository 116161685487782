<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <darkstar-css-one />
    <vs-css-one />
    <txpatterns-css-one />
    <misc-wordpress-one />
    <frogg-css-one />
    <nhs-css-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import DarkstarCssOne from '../../components/project-segments/darkstar/CssOne'
import VsCssOne from '../../components/project-segments/virtualsegmentation/CssOne'
import TxpatternsCssOne from '../../components/project-segments/txpatterns/CssOne'
import MiscWordpressOne from '../../components/project-segments/misc/WordpressOne'
import FroggCssOne from '../../components/project-segments/frogg/CssOne'
import NhsCssOne from '../../components/project-segments/nhs/CssOne'

export default {
    name: 'Css',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        DarkstarCssOne, VsCssOne, TxpatternsCssOne, MiscWordpressOne, FroggCssOne, NhsCssOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'CSS'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
