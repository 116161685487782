import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VTooltip from 'v-tooltip'
import VueCodeHighlight from 'vue-code-highlight'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

let VueScrollTo = require('vue-scrollto')

Vue.use(VTooltip)
Vue.use(VueScrollTo)
Vue.use(VueCodeHighlight)

VTooltip.enabled = window.innerWidth > 768

import '@/assets/styles/global.scss'

Vue.config.productionTip = false
sessionStorage.setItem('pageCount', 0)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
