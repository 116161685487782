<template>
  <segment-wrapper category="illustrator" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="logos" backgroundColor="#fcfcfc" />
    </template>

    <template v-slot:text>
      <p>With all the changes taking place, I created a rebrand to something more memorable
        and meaningful. The new domain extensions were just being rolled out, so I created a
        logo that incorporated the domain name; Southeast.bike.</p>

      <p>I chose Roboto Slab as it has a modern but trustworthy appearance, with the green
        representing the outdoor world, the hue reflecting the modern, electronic side of the brand.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'IllustratorOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      logos: [
        {
          thumb: 'images/southeastbike/southeastbike-logos.jpg',
          description: 'Southeast.bike - a web address and a logo',
          width: 527,
          height: 296
        }
      ]
    }
  },
}
</script>
