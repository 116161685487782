<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <frogg-vue-one />
    <misc-vue-one />
    <misc-vue-two />
    <txpatterns-vue-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import FroggVueOne from '../../components/project-segments/frogg/VueOne'
import MiscVueOne from '../../components/project-segments/misc/VueOne'
import MiscVueTwo from '../../components/project-segments/misc/VueTwo'
import TxpatternsVueOne from '../../components/project-segments/txpatterns/VueOne'

export default {
    name: 'Vue',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        FroggVueOne, MiscVueOne, MiscVueTwo, TxpatternsVueOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Vue'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
