<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="homeScreens" arrange="match-width" />
    </template>

    <template v-slot:text>
      <p>I chose to create the site in a frame that would be visible in HD monitors,
        and would keep everything proportionate as screen sizes increased. From there
        containers within had max widths set using the golden ratio as often as possible.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      homeScreens: [
        {
          thumb: 'images/bathe/home-desktop.jpg',
          description: 'Homepage on desktop',
          fullsize: 'images/bathe/home-desktop.jpg',
          class: 'browser-hs',
          width: 804,
          height: 483
        },
        {
          thumb: 'images/bathe/home-phone.jpg',
          description: 'Homepage on mobile',
          fullsize: 'images/bathe/home-phone.jpg',
          class: 'browser-hs',
          width: 250,
          height: 521
        }
      ]
    }
  },
}
</script>
