<template>
  <segment-wrapper category="laravel" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>I decided to pass this data into the api as opposed to into a page controller as php, 
        as this worked best with a questionnaire Vue component that I used as a starting point.</p>

      <p>I combined the questions and answer options at this point based on the related question Id.</p>

      <p>If security was to be of more concern I would have split the correct answer information and 
        fetched that at the end of the test, but this wasn’t deemed necessary for a test of this type.</p>
	
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'LaravelTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
class QuestionairreController extends ApiController
{

  public function questionairre(Request $request)
  {
    $questionList = [];
    ...

    foreach (Answer::all() as $item) {
      $answerId = $item->id;
      $answerText = $item->text;
      $answerSortOrder = $item->sort_order;
      $answerIsCorrect = $item->is_correct;
      $answerToQuestionId = $item->question_id;

      $answerList[] = array(
        'id' => $answerId,
        'text' => $answerText,
        'answer_sort_order' => $answerSortOrder,
        'answer_is_correct' => $answerIsCorrect,
        'question_id' => $answerToQuestionId
      );
    }

    foreach (Question::all() as $item) {
      $questionText = $item->text;
      $questionId = $item->id;
      $answerToQuestion = [];
      $correctAnswerCount = 0;

      foreach($answerList as $index=>$answer) {
        if ($answer['question_id'] == $questionId) {
          $answerToQuestion[] = $answerList[$index];
          if ($answerList[$index]['answer_is_correct']) {
            $correctAnswerCount++;
          }
        }
      }

      $questionList[] = array(
        'question' => $questionText,
        'question_id' => $questionId,
        'chosen_answers' => [],
        'answer_count' => $correctAnswerCount,
        'answers' => $answerToQuestion
      );
    }

    return $this->response([
      'success' => true,
      'data' => [
        'questions' => $questionList
      ],
    ]);
  }
}`
    }
  }
}
</script>
