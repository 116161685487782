<template>
  <segment-wrapper category="vue">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The ‘next’ button was also disabled until the correct number of answers were provided. 
        To prevent too many answers being provided I added a trim function which proved straightforward 
        when using v-model.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<button 
  @click="questionairre.moveToNext" 
  class="btn rounded-full bg-primary border-primary text-primary_contrasting" 
  :class="{ is_disabled: questionairre.current_question.chosen_answers.length != questionairre.current_question.answer_count}">
  @{{ ((questionairre.current_question_index + 1) == questionairre.question_count) ? 'Complete' : 'Next' }}
</button>

trim(val) {
  var self = this;
  let noOfAnswers = self.questions[self.current_question_index].answer_count;
  if (val.length > noOfAnswers) {
    val.shift();
  } 
}`
    }
  }
}
</script>
