<template>
  <div class="project-page nhs">
    <nav-bar/>
    <project-heading>
      <template v-slot:icon>
        <img width="100%" :src="require('../assets/images/home/nhs.svg')" alt="NHS logo" />
      </template>

      <template v-slot:summary>
        <li>Web tool built into existing Wordpress site</li>
        <li>Questions/answers added as custom post types, fetched from API and sorted via Javascript/CSS</li>
        <li>Custom fields in Wordpress admin allow customer to edit content and flow of questionairre</li>
        <li>Custom CSS animations so question cards 'shuffle', frames smoothly transition in height and zoom beteen stages</li>
      </template>
    </project-heading>
    
    <wordpress-one />
    <javascript-one />
    <css-one />
    <javascript-two />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import WordpressOne from '../components/project-segments/nhs/WordpressOne'
import JavascriptOne from '../components/project-segments/nhs/JavascriptOne'
import CssOne from '../components/project-segments/nhs/CssOne'
import JavascriptTwo from '../components/project-segments/nhs/JavascriptTwo'

export default {
    name: 'NHS',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        WordpressOne, JavascriptOne, CssOne, JavascriptTwo
    },

    created() {  
      document.title = 'NHS Safeguarding Tool'
      document.head.querySelector('meta[name=description]').content = 'NHS safeguarding webtool project by Ben Wain.'
    }
  }
</script>
