<template>
  <div class="project-page meantime">
    <nav-bar/>
    <project-heading>
      <template v-slot:icon>
        <img :src="require('../assets/images/home/meantime.svg')" alt="Meantime Brewing Company" />
      </template>

      <template v-slot:summary>
        <li>Extended theming in Shopify CLI, including custom Sass Webpack compiling to match Shopify's efficient style calling</li>
        <li>Custom Sections with fully dynamcic repeatable blocks controlled through CMS</li>
        <li>Custom product themes introduced via Product Metafields</li>
        <li>Git workflow added through Shopify Github integration so any small changes are fully tracked</li>
      </template>
    </project-heading>

    <photoshop-one />
    <shopify-one />
    <shopify-two />
    <shopify-three />
    <shopify-four />
    <sass-one />
    <javascript-one />
    <git-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/meantime/PhotoshopOne'
import ShopifyOne from '../components/project-segments/meantime/ShopifyOne'
import ShopifyTwo from '../components/project-segments/meantime/ShopifyTwo'
import ShopifyThree from '../components/project-segments/meantime/ShopifyThree'
import ShopifyFour from '../components/project-segments/meantime/ShopifyFour'
import SassOne from '../components/project-segments/meantime/SassOne.vue'
import JavascriptOne from '../components/project-segments/meantime/JavascriptOne.vue'
import GitOne from '../components/project-segments/meantime/GitOne.vue'

export default {
    name: 'Meantime',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, ShopifyOne, ShopifyTwo, ShopifyThree, ShopifyFour, SassOne, JavascriptOne, GitOne
    },

    created() {  
      document.title = 'Meantime Brewing Company Ecommerce Solution'  
      document.head.querySelector('meta[name=description]').content = 'Meantime Brewing Company Ecommerce solution using Shopify CLI'
    }
  }
</script>
