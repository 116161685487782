<template>
  <segment-wrapper category="illustrator" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="styleGuide" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>In spare time around the project I created an icon set to be used with the vehicle
        spec items. I felt some of the metaphors were a bit weak rendering their use fairly
        pointless. Using a fuel pump instead of a water droplet for fuel type made a lot
        more sense, and would actually make a users life easier as opposed to cluttering
        the page unnecessarily.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'IllustratorOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      styleGuide: [
        {
          thumb: 'images/advanced4/icons.jpg',
          description: 'Icon set',
          fullsize: 'images/advanced4/icons.jpg',
          width: 559,
          height: 388
        }
      ]
    }
  },
}
</script>
