<template>
  <segment-wrapper category="wordpress" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
      <div class="doughnut-chart">
        <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
          <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
          <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5"></circle>
          <circle class="donut-segment donut-segment-3" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="100 0" stroke-dashoffset="25"></circle>
          <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="97 3" stroke-dashoffset="25"></circle>
          <circle class="donut-segment donut-segment-1" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="70 30" stroke-dashoffset="25"></circle>
          <g class="donut-text">
            <text y="50%" transform="translate(0, 1)">
              <tspan x="50%" text-anchor="middle" class="donut-percent">70%</tspan>  
            </text>
            <text y="60%" transform="translate(0, 3)">
              <tspan x="50%" text-anchor="middle" class="donut-data">filler</tspan>  
            </text>
          </g>
        </svg>
      </div>
      <p>To animate the SVG chart, I targetted the stroke-dasharray with CSS keyframe animation.
		  As each value was populated from an Advanced Custom Field in Wordpress Admin, I had add the keyframes inline in the page.
      </p>

	  <p>With each segment stacked, I achieved the required result by adding the values from any overlaying layers to the one added
		  by the customer in the admin panel. This meant each segment could start at 12 O'clock at expand the correct amount ahead of
		  the segment over it.
	  </p>

	  <p>Scrollmagic was then used to trigger the animation when the user had scrolled the chart into view.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'WordpressOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<?php
$post_id = 330;
$queried_post = get_post($post_id); ?>

<?php
$harmful = isset(get_field('potential_harmful', $post_id)) ? intval(get_field('potential_harmful', $post_id)) : 0;
$poor = isset(get_field('nutritionally_poor', $post_id)) ? intval(get_field('nutritionally_poor', $post_id)) : 0;
$filler =  $harmful + $poor;
$speciesSpecific = isset(get_field('species_specific', $post_id)) ? intval(get_field('species_specific', $post_id)) : 0;
$favoured = isset(get_field('favoured', $post_id)) ? intval(get_field('favoured', $post_id)) : 0;
$speciesSpecific = $speciesSpecific + $filler;
$favoured = $favoured + $speciesSpecific;
?>

<style>
.segment-1 {
	stroke: #3c3c3b;
	animation: segment1 6s infinite;
}
...

@keyframes segment1 {
   0% {
       stroke-dasharray: 0, 100;
   }
   100% {
       stroke-dasharray: <?php echo $filler . ', ' . (100 - $filler); ?>;
   }
}
...
</style>

<div class="doughnut-chart">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5"></circle>
    <circle class="donut-segment segment-3" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="<?php echo $favoured . ' ' . (100 - $favoured); ?>" stroke-dashoffset="25"></circle>
    <circle class="donut-segment segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="<?php echo $speciesSpecific . ' ' . (100 - $speciesSpecific); ?>" stroke-dashoffset="25"></circle>
    <circle class="donut-segment segment-1" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="5" stroke-dasharray="<?php echo $filler . ' ' . (100 - $filler); ?>" stroke-dashoffset="25"></circle>
    <g class="donut-text">
      <text y="50%" transform="translate(0, 1)">
        <tspan x="50%" text-anchor="middle" class="donut-percent">70%</tspan>  
      </text>
      <text y="60%" transform="translate(0, 3)">
        <tspan x="50%" text-anchor="middle" class="donut-data">filler</tspan>  
      </text>
    </g>
  </svg>
</div>`
    }
  },
}
</script>
<style lang="scss" scoped>
.doughnut-chart {
	width: 100%;
	font-size: 16px;
	margin: 0 auto 2rem;
	max-width: 300px;
	animation: donutfade 6s infinite;
}

@keyframes donutfade {
	0% {
		opacity: .2;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes segment1 {
	0% {
		stroke-dasharray: 0, 100;
	}
	25% {
		stroke-dasharray: 70, 30;
	}
	75% {
		stroke-dasharray: 70, 30;
	}
	100% {
		stroke-dasharray: 0, 100;
	}
}

@keyframes segment2 {
	0% {
		stroke-dasharray: 0, 100;
	}
	25% {
		stroke-dasharray: 97, 3;
	}
	75% {
		stroke-dasharray: 97, 3;
	}
	100% {
		stroke-dasharray: 0, 100;
	}
}

@keyframes segment3 {
	0% {
		stroke-dasharray: 0, 100;
	}
	25% {
		stroke-dasharray: 100, 0;
	}
	75% {
		stroke-dasharray: 100, 0;
	}
	100% {
		stroke-dasharray: 0, 100;
	}
}

.donut-ring {
	stroke: #EBEBEB;
}

.donut-segment {
	transform-origin: center;
}

.donut-segment-1 {
	stroke: #3c3c3b;
	animation: segment1 6s infinite;
}

.donut-segment-2 {
	stroke: #ffd511;
	animation: segment2 6s infinite;
}

.donut-segment-3 {
	stroke: #74a858;
	animation: segment3 6s infinite;
}

.donut-percent {
	animation: donutfadelong 6s infinite;
}

@keyframes donutfadelong {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.donut-text {
	fill: #3c3c3b;
}

.donut-percent {
	font-size: 0.65em;
	line-height: 1;
	transform: translateY(0.5em);
	font-weight: bold;
}

.donut-data {
	font-size: 0.4em;
	line-height: 1;
	transform: translateY(0.5em);
	text-align: center;
	text-anchor: middle;
	color: #3c3c3b;
	fill: #3c3c3b;
	animation: donutfadelong 6s;
}


</style>
