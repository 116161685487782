<template>
  <segment-wrapper category="vue">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The project was built on Laravel and Vue. Api calls ran in the background to check and
        update data. The tables with vital information had a status indicator and counter to let
        the user know if they were viewing the most recent information.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<p v-if="txonline" class="tx-online">Online - Last updated {{getMinutes}}{{getSeconds}} Seconds ago</p>
<p v-else class="tx-offline">Offline - UI currently not updated (retrying)</p>

data() {
  return {
    txonline: false, minutes: 0, seconds: 0
  }
},

methods: {
  fetch() { ... }
},

computed: {
  getSeconds() { ... },
  getMinutes() { ... }
},

mounted() {
  this.interval = setInterval(function () {
    this.fetch()
  }.bind(this), 20000)
}`
    }
  },
}
</script>
