<template>
  <segment-wrapper category="css" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
      <div class="video-wrapper" style="background-image: url(@/assets/images/nhs/nhs-safeguarding.jpg)">
        <video loop autoplay>
          <source src="@/assets/videos/nhs-safeguarding.mp4"  type="video/mp4">
        </video>
      </div>

      <p>To create the illusion of shuffling through cards, I set a translateX and scale animation on the card 
        moving out of frame, and a scale animation on the card moving in.</p>

      <p>The opacity meant that the next card could be a lot taller or shorter than the active card and it 
        wouldn’t matter how the stack looked. The card behind was just a static ::after pseudo element.</p>

      <p>Transitions of scale and opacity were also set between stages of the user journey, fading in/out 
        loading screens and scale for questions/answers.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'CssOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
@keyframes forwards-card-in {
    0% {
      z-index: 1;
      transform: scale(0.8);
      opacity: 0;
    }

    100% {
      z-index: 2;
      transform: translateX(0) scale(1);
      opacity: 1;
    }	
}

@keyframes forwards-card-out {
  0% {
    z-index: 2;
    transform: scale(1);
    margin-top: 0;
  }

  50% {
    transform: translateX(100%);
    opacity: 1;
  }
  
  100% {
    z-index: 1;
    transform: scale(0.8) translateX(0);
    margin-top: -30px;
    opacity: 0;
  }
}`
    }
  },
}
</script>
