<template>
  <segment-wrapper category="css">
    <template v-slot:media>
      <image-layout :images="homeScreens" arrange="match-width" />
    </template>

    <template v-slot:text>
      <p>By using :nth-child pseudo selector, I was able to offer a different layout to the last CTA 
        with relatively little CSS, whilst offering the same options in the admin interface. As in the design, this 
        was to contain more text about a feature item and span the width of the grid. For 
        this item the content and image stacked on mobile (the others remained overlays).</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'CssOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      homeScreens: [
        {
          thumb: 'images/darkstar/dark-star-custom-cta-desktop.jpg',
          description: 'Homepage on desktop',
          fullsize: 'images/darkstar/dark-star-custom-cta-desktop.jpg',
          class: 'browser-hs',
          width: 804,
          height: 483
        },
        {
          thumb: 'images/darkstar/dark-star-custom-cta-mobile.jpg',
          description: 'Homepage on mobile',
          fullsize: 'images/darkstar/dark-star-custom-cta-mobile.jpg',
          class: 'browser-hs',
          width: 250,
          height: 521
        }
      ]
    }
  },
}
</script>