<template>
  <div class="project-page txpatterns">
    <nav-bar/>
    <project-heading>
      <template v-slot:icon>
        <img :src="require('../assets/images/home/tx.svg')" alt="Technetix logo" />
      </template>

      <template v-slot:summary>
        <li>Instigated off own initiative after noticing limited coherence between digital projects.</li>
        <li>Now part of the process and requested regularly internationally.</li>
        <li>Sole Designer/Front End Developer.</li>
        <li>Award winning company and innovations, offices in 18 countries with global presence.</li>
      </template>
    </project-heading>

    <photoshop-one />
    <photoshop-two />
    <bulma-one />
    <photoshop-three />
    <vue-one />
    <photoshop-four />
    <css-one />
    <sass-one />

    <foot/>
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/txpatterns/PhotoshopOne'
import PhotoshopTwo from '../components/project-segments/txpatterns/PhotoshopTwo'
import PhotoshopThree from '../components/project-segments/txpatterns/PhotoshopThree'
import PhotoshopFour from '../components/project-segments/txpatterns/PhotoshopFour'
import VueOne from '../components/project-segments/txpatterns/VueOne'
import SassOne from '../components/project-segments/txpatterns/SassOne'
import CssOne from '../components/project-segments/txpatterns/CssOne'
import BulmaOne from '../components/project-segments/txpatterns/BulmaOne'

export default {
    name: 'TechnetixPatterns',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, PhotoshopTwo, PhotoshopThree, PhotoshopFour, VueOne, SassOne, CssOne, BulmaOne
    },

    data () {
      return {
      }
    },

    created() {  
      document.title = 'Technetix Software Patterns'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>

<style lang="scss">
.txpatterns {
  #photoshop-four .text-wrap{
    display: none;
  }
}
</style>
