<template>
  <segment-wrapper category="sass" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/meantime/animated-hero.png')" alt="Animated hero banner" width="519" height="355" /></p>

      <p>The animated hero included each cog as SVG code within a liquid snippet, and the layout of 
        which was determined by the page class (this was also used on the about page). The 
        rotation was created with a css animation, each cog absolute positioned, using 
        either vw's or a set width dependent on viewport in order to keep things proportionate. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'SassOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
.animated-hero {
  .cog- {
    &red-, &yellow-, &green-, &teal-, &black-, &green-solid- {
      &wrapper {
        position: absolute;
      }
    }
  }

  @media screen and (max-width: 599px) {
    //mobile layout - sizes of cogs in vw units
  }

  @media screen and (min-width: 600px) and (max-width: 1699px) {
    //above mobile layout - relative sizes in vw units
  }

  @media screen and (min-width: 1700px) {
    //lock element sizes for large screens - hero height also set
  }

  .page-home & {
  }

  .page-about & {
  }
}`
    }
  },
}
</script>

<style lang="scss">
.meantime {
  #sass-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #meantime-sass-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>
