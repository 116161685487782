<template>
  <div class="project-page asahi">
    <nav-bar/>
    <project-heading>
      <template v-slot:icon>
        <img width="100%" :src="require('../assets/images/home/asahi.svg')" alt="Asahi logo" />
      </template>

      <template v-slot:summary>
        <li>Questionnaire app controlled by data entered via Laravel's Nova CMS</li>
        <li>Related question and answer content types, ability to enter multiple correct answers with accurate scoring algorithm</li>
        <li>Custom API structure</li>
        <li>Lightweight build utilizing Vue components and transitions</li>
      </template>
    </project-heading>
    
    <laravel-one />
    <laravel-two />
    <javascript-one />
    <vue-one />
    <vue-two />
    <javascript-two />
    <vue-three />
    <vue-four />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import LaravelOne from '../components/project-segments/asahi/LaravelOne'
import LaravelTwo from '../components/project-segments/asahi/LaravelTwo'
import JavascriptOne from '../components/project-segments/asahi/JavascriptOne'
import VueOne from '../components/project-segments/asahi/VueOne'
import VueTwo from '../components/project-segments/asahi/VueTwo'
import JavascriptTwo from '../components/project-segments/asahi/JavascriptTwo'
import VueThree from '../components/project-segments/asahi/VueThree'
import VueFour from '../components/project-segments/asahi/VueFour'


export default {
    name: 'Asahi',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        LaravelOne, LaravelTwo, JavascriptOne, VueOne, VueTwo, JavascriptTwo, VueThree, VueFour
    },

    created() {  
      document.title = 'Asahi Contractor H&S Questionnaire Web App'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
