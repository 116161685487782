<template>
  <segment-wrapper category="jquery" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Another library I investigated was Jquery Validate. But this didn’t seem to offer
        accurate validation on phone numbers, so I ended up creating my own validation code
        using a little regex.</p>

      <p>I eventually added 3 layers of protection to the web forms: Frontend, using example 
        seen here, honeypot for spambots, and finally PHP to strip tags and special characters
        for scripting attacks.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JqueryThree',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
$('#phone').on('keyup', function() {
  var input = $(this);
  var conditions = /^(([0-9]{4,5})\s?([0-9]{6,7}))*$/;
  var val = conditions.test(input.val());
  if (val && input.val() != '') {
    input.removeClass('is-danger');
    $('#phoneHelp').hide();
    phoneErrors = false;
  } else {
    input.addClass('is-danger');
    $('#phoneHelp').show();
    phoneErrors = true;
  }
});

var inputs = $('#callMeBack').find('input');
inputs.keyup(function() {
  if (phoneErrors || locationErrors) {
    $('#submit').prop('disabled', true);
  } else {
    $('#submit').prop('disabled', false);
  }
});`
    }
  }
}
</script>