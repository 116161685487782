<template>
  <div id="app" :class="{ready: isPageReady}">
    <transition-page>
      <router-view/>
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from './transitions/TransitionPage';

export default {
  name: 'App',

  components: {
    TransitionPage
  },

  data() {
    return {
      isPageReady: false, 
    }
  },

  created () {
    this.$store.dispatch('AutoLogin')
  },

  mounted() {
    this.$nextTick(function () {
      this.isPageReady = true;
    });
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #2c3e50;
  color: #000;
  opacity: 0;

  &.ready {
    opacity: 1;
    transition: opacity .5s ease-in-out;

    + .half-circle-spinner {
      display: none;
    }
  }

  .animin {
    opacity: 0;
  }
}
</style>
