<template>
  <div class="project-page advanced4">
    <nav-bar/>
    <project-heading>
      <template v-slot:icon>
        <img :src="require('../assets/images/home/advanced4.svg')" alt="Spidersnet icon" />
      </template>

      <template v-slot:summary>
        <li>Fourth template option for mid-weight paying customers.</li>
        <li>Keep consistent with branding but improve features.</li>
        <li>Sole Designer.</li>
        <li>Fixed layout but customisable features like colour, iconography.</li>
        <li>Worked closely with Product Manager to deliver spec, Head Developer to keep realistic.</li>
        <li>Spidersnet, part of Digital Agency Friday Media Group - 300 employees.</li>
        <li>Template a quantum leap forward - other templates improved following this to bring in line.</li>
      </template>
    </project-heading>

    <photoshop-one />
    <photoshop-two />
    <photoshop-three />
    <photoshop-four />
    <illustrator-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/advanced4/PhotoshopOne'
import PhotoshopTwo from '../components/project-segments/advanced4/PhotoshopTwo'
import PhotoshopThree from '../components/project-segments/advanced4/PhotoshopThree'
import PhotoshopFour from '../components/project-segments/advanced4/PhotoshopFour'
import IllustratorOne from '../components/project-segments/advanced4/IllustratorOne'

export default {
    name: 'Advanced4',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, PhotoshopTwo, PhotoshopThree, PhotoshopFour, IllustratorOne
    },

    created() {  
      document.title = 'Advanced 4 Template Solution'  
      document.head.querySelector('meta[name=description]').content = 'Mid-weight template solution for Spidersnet.'
      document.head.querySelector('meta[name=robots]').content = 'noindex'  
    }
  }
</script>
