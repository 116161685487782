<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <southeastbike-foundation-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import SoutheastbikeFoundationOne from '../../components/project-segments/southeastbike/FoundationOne'

export default {
    name: 'Foundation',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        SoutheastbikeFoundationOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Foundation'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
