<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/frogg/filesystem.jpg')" alt="File structure" width="299" height="265" /></p>

      <p>The product details modal featured a 360 image spin in the design. I used a 3rd party Vue
        extension, which relied on an array of images being populated to work.
      </p>

      <p>The easiest way to achieve this was to name each image file the number in sequence, then 
        match that to the ID in a for loop.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptTwo',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
for(let i = 0; i < this.currentProduct.spinImageCount; i++) {
  this.spinImages.push('/assets/img/360/' + this.currentProduct.imgDirName + '/' + (i+1) + '.jpg');
}`
    }
  }
}
</script>
