<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="homeScreens" arrange="match-width" />
    </template>

    <template v-slot:text>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      homeScreens: [
        {
          thumb: 'images/meantime/meantime-browser-desktop.jpg',
          description: 'Homepage on desktop',
          fullsize: 'images/meantime/meantime-browser-desktop.jpg',
          class: 'browser-hs',
          width: 804,
          height: 483
        },
        {
          thumb: 'images/meantime/meantime-browser-mobile.jpg',
          description: 'Homepage on mobile',
          fullsize: 'images/meantime/meantime-browser-mobile.jpg',
          class: 'browser-hs',
          width: 250,
          height: 521
        }
      ]
    }
  },
}
</script>

<style lang="scss">
.meantime {
  #photoshop-one {
    &.duskblue-border {
      border-color: transparent;
    }

    .top-tag {
      display: none;
    }

    .text-wrap {
      display: none;
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .meantime {
    #photoshop-one {
      .image-wrap {
        border-radius: $radius;
      }
    }
  }
}
</style>