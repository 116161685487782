<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <bathe-photoshop-one />
    <bathe-photoshop-two />
    <bathe-photoshop-three />
    <vs-photoshop-one />
    <vs-photoshop-two />
    <southeastbike-photoshop-one />
    <southeastbike-photoshop-two />
    <txpatterns-photoshop-one />
    <txpatterns-photoshop-two />
    <txpatterns-photoshop-three />
    <txpatterns-photoshop-four />
    <a4-photoshop-one />
    <a4-photoshop-two />
    <a4-photoshop-three />
    <a4-photoshop-four />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import BathePhotoshopOne from '../../components/project-segments/bathe/PhotoshopOne'
import BathePhotoshopTwo from '../../components/project-segments/bathe/PhotoshopTwo'
import BathePhotoshopThree from '../../components/project-segments/bathe/PhotoshopThree'
import VsPhotoshopOne from '../../components/project-segments/virtualsegmentation/PhotoshopOne'
import VsPhotoshopTwo from '../../components/project-segments/virtualsegmentation/PhotoshopTwo'
import SoutheastbikePhotoshopOne from '../../components/project-segments/southeastbike/PhotoshopOne'
import SoutheastbikePhotoshopTwo from '../../components/project-segments/southeastbike/PhotoshopTwo'
import TxpatternsPhotoshopOne from '../../components/project-segments/txpatterns/PhotoshopOne'
import TxpatternsPhotoshopTwo from '../../components/project-segments/txpatterns/PhotoshopTwo'
import TxpatternsPhotoshopThree from '../../components/project-segments/txpatterns/PhotoshopThree'
import TxpatternsPhotoshopFour from '../../components/project-segments/txpatterns/PhotoshopFour'
import A4PhotoshopOne from '../../components/project-segments/advanced4/PhotoshopOne'
import A4PhotoshopTwo from '../../components/project-segments/advanced4/PhotoshopTwo'
import A4PhotoshopThree from '../../components/project-segments/advanced4/PhotoshopThree'
import A4PhotoshopFour from '../../components/project-segments/advanced4/PhotoshopFour'

export default {
    name: 'Photoshop',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        BathePhotoshopOne, BathePhotoshopTwo, BathePhotoshopThree, VsPhotoshopOne, VsPhotoshopTwo, SoutheastbikePhotoshopOne, SoutheastbikePhotoshopTwo, TxpatternsPhotoshopOne, TxpatternsPhotoshopTwo, TxpatternsPhotoshopThree, TxpatternsPhotoshopFour, A4PhotoshopOne, A4PhotoshopTwo, A4PhotoshopThree, A4PhotoshopFour
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Photoshop'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>

<style lang="scss">
.tech-page {
  #txpatterns-photoshop-four {
    .text-wrap {
      display: none;
    }
  }
}
</style>