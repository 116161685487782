<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The landing tiles were to set to fill the viewport height if the screen was wider than it was tall
        (and not taller than the viewort), and collapse to its normal content height if not. This was
        achieved by adding a class .is-fullheight (min-height: 100vh) on tablet and up, wth javascript
        checking the container dimensions.
      </p>
      <p>I also added the function to trigger on screen resize for when a user rotates a devive, but later
        removed this as it struggled with the collapsing address bar when a user scrolls on tablet. The
        negative implications of leaving this in (flickering on scroll) outweighed the minor visual
        imperfection caused when removed. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
orientation() {
  let getWidth = window.innerWidth
  let getHeight = window.innerHeight
  let landingHeight = landingScreen.getBoundingClientRect().height

  if (getWidth > (getHeight + 200) && landingHeight <= getHeight) {
      landingScreen.classList.add('is-fullheight')
      belowFold.classList.add('is-fullheight')
  } else {
      landingScreen.classList.remove('is-fullheight')
      belowFold.classList.remove('is-fullheight')
  }
}`
    }
  }
}
</script>
