<template>
  <div class="tech-page laravel">
    <nav-bar/>
    <tech-heading />

    <misc-laravel-one />
    <asahi-laravel-one />
    <asahi-laravel-two />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import SegmentWrapper from '../../components/SegmentWrapper'
import Foot from '../../components/Foot'

import MiscLaravelOne from '../../components/project-segments/misc/LaravelOne'
import AsahiLaravelOne from '../../components/project-segments/asahi/LaravelOne'
import AsahiLaravelTwo from '../../components/project-segments/asahi/LaravelTwo'

export default {
    name: 'Laravel',

    components: {
        NavBar, TechHeading, TechLogo, SegmentWrapper, Foot,
        MiscLaravelOne, AsahiLaravelOne, AsahiLaravelTwo
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Laravel'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
