<template>
  <segment-wrapper category="git">
    <template v-slot:media>
      <image-layout :images="map" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Shopfiy's option to connect to a GitHub repo is fantastic in that it pulls and pushes changes between the 
        two automatically. This means whatever changes are made on the live store are tracked. At first I was a little 
        nervous connecting the master branch directly to live as ideally master should contain only tested code. But 
        as the customer would only be making infrequent schema changes I thought this model would work, and only 
        created one other repo for connecting local development work to a preview theme. A more active store would 
        benefit from a 'live' repo capturing live changes, a 'dev'repo for testing development work and master. In 
        this case scheduled code reviews/merges would then be required.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'GitOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      map: [
        {
          thumb: 'images/meantime/shopify-git.jpg',
          description: 'Homepage on desktop',
          fullsize: 'images/meantime/shopify-git.jpg',
          class: 'meantime-git',
          width: 1092,
          height: 628
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@media (min-width: $desktop) {
  .meantime-git {
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
