<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="ui" backgroundColor="#fff" />
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopFour',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      ui: [
        {
          thumb: 'images/txpatterns/page-components-2.jpg',
          description: 'Page UI components',
          // width: 804,
          // height: 483
        }
      ]
    }
  },
}
</script>
