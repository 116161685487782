<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/darkstar/agegate.jpg')" @load="imagesLoaded()" alt="Dark Star agegate" width="1000" height="600" /></p>

      <p>The agegate was one of the last components I worked on for the project, and simply grabbed the current date and added 18 years 
        for the question. If the user answered yes, a record is set in local storage and the overlay hidden. I did initially use a 
        cookie with a 30 day expiry, but some browsers were not allowing it to be set (meaning the overlay would show on every page visited!). 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
const agegateCookie = localStorage.getItem('agegateApproval');

if (agegateCookie == null) {

  function subtractYears() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toLocaleDateString('eg-gb', {day:"numeric", year:"numeric", month:"long"});
  }

  const eighteenYrsToDate = subtractYears();
  document.querySelector('.agegate-text').innerText = 'Were you born before ' + eighteenYrsToDate + '?';
  const ageApproved = document.querySelector('.button-yes');
  const ageReject = document.querySelector('.button-no');
  const agegateWrapper = document.querySelector('.agegate-wrapper');
  document.body.classList.add('agegate-open');

  ageApproved.addEventListener('click', function() {
    localStorage.setItem('agegateApproval', 'true');
    document.body.classList.remove('agegate-open');
  });

  ageReject.addEventListener('click', function() {
    agegateWrapper.classList.add('agegate-rejected');
  });
}
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>
