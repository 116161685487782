<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/meantime/product-meta.png')" @load="imagesLoaded()" alt="Product Meta" width="519" height="286" /></p>

      <p>As with sister-project Meantime, products had their own theme imagery and colouring, which carried through the custom sections underneath 
        the product. These could be added via custom metafields and be accessed wherever necessary within the site.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyFour',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- if product.type -%}
    <dl>
        {% render 'star-bullet' %}
        <dt>Type</dt>
        <dd>{{ product.type }}</dd>
    </dl>
{%- endif -%}
{%- if product.metafields.custom.malts.value -%}
    <dl>
        {% render 'star-bullet' %}
        <dt>Malts</dt>
        <dd>{% assign malts = product.metafields.custom.malts.value %}{{ malts | join: ", "  }}</dd>
    </dl>
{%- endif -%}
{%- if product.metafields.custom.hops.value -%}
    <dl>
        {% render 'star-bullet' %}
        <dt>Hops</dt>
        <dd>{% assign hops = product.metafields.custom.hops.value %}{{ hops | join: ", "  }}</dd>
    </dl>
{%- endif -%}
...
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.darkstar {
  #shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #darkstar-shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>