<template>
  <router-link :to="route" class="project" :class="tileClass">
    <figure>
        <div class="logo">
            <img :src="logo(tileClass)" :alt="name">
        </div>
        <figcaption>
            <h6>{{ name }}</h6>
            <small>{{ type }}</small>
        </figcaption>
    </figure>
  </router-link>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Tile',

  props: {
    name: {
      type: String
    },

    type: {
      type: String
    },

    route: {
      type: String
    },

    tileClass: {
      type: String
    },

    logoType: {
      type: String
    }
  },

  data() {
    return {
      themeColour: '',
      locked: true,
      logoFileType: ''
    }
  },

  methods: {
    
    logo(name) {
      let image = require('../assets/images/home/' + name + '.' + this.logoFileType)
      return image
    }
  },

  created() {
    const routes = this.$router.options.routes
    for (let i = 0; i < routes.length; i ++) {
      let route = routes[i].path
      if (route == this.route) {
        this.themeColour = routes[i].meta.themeColour
        this.locked = routes[i].meta.locked
        this.logoFileType = routes[i].meta.logoFileType
      }
    }
  },

  mounted() {
    // let tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".logo-pool",
    //     toggleActions: "play none none reverse",
    //   }
    // })

    // tl.from('.logo-pool a', {opacity: 0})
    // tl.to('.logo-pool a', {opacity: 1, stagger: .1})

    

  }
}
</script>

<style lang="scss">
.project-list {
    .project {
        position: relative;
        display: flex;
        box-shadow: 0 0 0 0 transparent;
        border: 2px solid #eee;
        border-radius: 8px;
        transition: all .2s ease-in-out;

        figure {
            position: relative;
            display: flex;
            flex-grow: 1;
            height: 100%;
            border-radius: 8px;
            padding: 1rem;
            gap: 1rem;
            background: white;
            z-index: 2;

            figcaption {
              line-height: 1.3;
            }

            h6 {
              margin-bottom: .35rem;
            }

        }

        // &::after {
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     content: '';
        //     width: 100%;
        //     height: 80%;
        //     transform: translate(-50%, -40%);
        //     box-shadow: 0 0 0 1px transparent;
        //     transition: all .3s ease-in-out;
        //     z-index: -1;
        // }

        &.nhs {
          &:hover {
            border-color: rgb(0, 94, 184);
            box-shadow: 0 0 0 4px rgba(0, 94, 184, .4);
          }
        }

        &.frogg {
          &:hover {
            border-color: rgb(0, 196, 97);
            box-shadow: 0 0 0 4px rgba(0, 196, 97, .4);
          }
        }

        &.bathe {
          &:hover {
            border-color: rgb(84, 186, 164);
            box-shadow: 0 0 0 4px rgba(84, 186, 164, .4);
          }
        }
        
        &.vs {
          &:hover {
            border-color: rgb(7, 158, 218);
            box-shadow: 0 0 0 4px rgba(7, 158, 218, .4);
          }
        }

        &.asahi {
          &:hover {
            border-color: rgb(0, 67, 145);
            box-shadow: 0 0 0 4px rgba(0, 67, 145, .4);
          }
        }
        
        &.tx-patterns {
          &:hover {
            border-color: rgb(11, 40, 94);
            box-shadow: 0 0 0 4px rgba(11, 40, 94, .4);
          }
        }
         
        &.meantime {
          &:hover {
            border-color: rgb(0, 0, 0);
            box-shadow: 0 0 0 4px rgba(0, 0, 0, .4);
          }
        }

        &.darkstar {
          &:hover {
            border-color: rgb(234, 6, 40);
            box-shadow: 0 0 0 4px rgba(234, 6, 40, .4);
          }
        }


        &:hover {
            //box-shadow: 0 0 0 1px #ccc;

            // &::after {
            //     width: 85%;
            //     height: 80%;
            //     transform: translate(-50%, -40%);
            //     border-radius: 20px;
                //box-shadow: 0 0 20px #00c461;
            //}
        }
    }
}

@media screen and (max-width: $tablet - 1px) {
    .project-list {
        .project {
            figure {
                flex-direction: column;
                text-align: center;
            }

            h6 {
                font-size: 80%;
            }

            .logo {
              display: flex;
              height: 17vw;


              img {
                max-height: 100%;
                margin: auto;
              }
            }

            &.frogg,
            &.bathe {
              img {
                max-height: 7vw;
              }
            }

            &.asahi {
              img {
                max-height: 7.2vw;
              }
            }

            &.nhs {
              img {
                max-height: 7vw;
              }
            }

            // &.vs {
            //   img {
            //     max-height: 9vw;
            //   }
            // }
        }
    }
}

@media screen and (min-width: $tablet) {
    .project-list {
        .project {
            align-items: flex-start;

            .logo {
                width: 25%;
                flex-shrink: 0;
            }
        }
    }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    .project-list {
        .project {
            .logo {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.frogg,
            &.bathe {
              img {
                max-width: 75%;
              }
            }

            &.asahi {
              img {
                max-width: 70%;
              }
            }

            &.nhs {
              img {
                max-width: 60%;
              }
            }

            &.meantime,
            &.darkstar {
              img {
                max-width: 65%;
              }
            }
        }
    }
}

@media screen and (min-width: $desktop) {
    .project-list {
        .project {

            &.darkstar,
            &.frogg,
            &.bathe,
            &.vs {
              img {
                max-width: 85%;
              }
            }

            &.meantime {
              img {
                max-width: 75%;
              }
            }
        }
    }
}
</style>
