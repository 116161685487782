<template>
  <segment-wrapper category="html" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <form style="margin-bottom: 1.5rem">
        <div class="field">
          <label>Formula: <input name="formula" list="data" autocomplete="on"></label>
          <datalist id="data">
              <option>Sin</option>
              <option>Cos</option>
              <option>Tan</option>
              <option>Cot</option>
          </datalist>
        </div>
        <div class="field">
          <label>Iterations: <input name="iterations" type="range" min="1" max="10" value="1"></label>
        </div>
        <div class="field">
          <label>Precision: <input name="precision" type="number" min="1" max="100" value="50"></label>
        </div>
      </form>
      <p>HTML5 form controls; no longer the same need for extensive scripts and styling!</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'HtmlTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<form>
  Formula: <input name="formula" list="data" autocomplete="on">
  <datalist id="data">
    <option>Sin</option>
    <option>Cos</option>
    <option>Tan</option>
    <option>Cot</option>
  </datalist>
  <br />
  Iterations: <input name="iterations" type="range" min="1" max="10" value="1"><br />
  Precision: <input name="precision" type="number" min="1" max="100" value="50"><br />
</form>`
    }
  },
}
</script>
