<template>
  <segment-wrapper category="drupal" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="venueDetails" />
    </template>

    <template v-slot:text>
      <p>Although the site’s focus was local news, most searches coming through were for venue
        information, which was weak in the previous site. To address this, the year before the
        site was spent travelling to venues, photographing and documenting information.</p>

      <p>The various types of riding were then rated, relative to what other venues in the area
        offered. This and other snippets of information were provided on ‘cards’ at the top, with
        detailed information below.</p>
      
      <p>With a lot of information on each venue came the next problem: maintaining it.</p>

      <p>As well as driving to the venues, I visited local shops to present the concept: The local
        shop could become page ‘manager’, by maintaining it and posting news. This in turn made them
        a ‘go to’ for people in the area or interested in the venue.</p>

      <p>The customisation of content types in Drupal and modules like Context allowed this to be set
        up relatively easily, Wordpress would require a great deal more bending to achieve the same
        results.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'DrupalOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      venueDetails: [
        {
          thumb: 'images/southeastbike/venue-details-full.jpg',
          description: 'Venue details page example',
          class: 'browser-hs',
          width: 495,
          height: 944
        },
      ]
    }
  },
}
</script>
