<template>
  <segment-wrapper category="jquery" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>A small amount of jQuery was used across the site, handling the overlay menu and 
        slideshows in various pages. The homepage search background wasn’t totally static 
        either; there were several images lined up that would be picked at random for each 
        refresh. This kept it fresh for a returning visitor.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JqueryOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
var images = ['bewl-cycling.jpg', 'porc-downhill.jpg', 's4p-dirt-jumps.jpg', 'sunset-trail.jpg'];
    
$('.home-parallax').css({
  'background-image': 'url(/themes/seb/images/' + images[Math.floor(Math.random() * images.length)] + ')'
});
	  
$(document).ready(function() {

	$('#lightSlider').lightSlider({
		...
	});
				
	$('.nav-button a').click(function(){
		...
	});

	$('.overlay').on('click', function(){
		...
	});
}),

$(window).scroll(function(){
	...
});`
    }
  }
}
</script>
