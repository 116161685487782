<template>
  <segment-wrapper category="photoshop" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="styleGuide" arrange="align-center" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>The only thing the customer had at this stage was turquoise logo. My first port of
        call was to build a colour palette of this, including user interface appropriate colours,
        like success and error colours.</p>

      <p>They weren’t able to provide the logo typeface, so I found the nearest free alternative
        and matched this with a sans serif for general site text.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      styleGuide: [
        {
          thumb: 'images/bathe/bathe-typography.jpg',
          description: 'Website typeface selection',
          width: 264,
          height: 135
        },
        {
          thumb: 'images/bathe/bathe-swatch.jpg',
          description: 'Brand web colours',
          width: 264,
          height: 135
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@media (min-width: 733px) and (max-width: 860px) {
  .bathe #photoshop-one .image-wrap {
    flex-direction: column;
  }
}
</style>
