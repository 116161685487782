<template>
  <div class="project-page virtual-segmentation">
    <nav-bar/>
    <project-heading backgroundColour="#090B17">
      <template v-slot:icon>
        <img :src="require('../assets/images/home/vs.svg')" alt="Virtual Segmentation logo" />
      </template>

      <template v-slot:summary>
        <li>Software built for accompanying hardware and its built-in firmware.</li>
        <li>Multinational project, spanning languages and timezones.</li>
        <li>Sole Designer/Front End Developer.</li>
        <li>Award winning company and innovations, offices in 18 countries with global presence.</li>
      </template>
    </project-heading>

    <photoshop-one />
    <photoshop-two />
    <vue-one />
    <vue-two />
    <css-one />
    <illustrator-one />

    <foot/>
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import SegmentWrapper from '../components/SegmentWrapper'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/virtualsegmentation/PhotoshopOne'
import PhotoshopTwo from '../components/project-segments/virtualsegmentation/PhotoshopTwo'
import VueOne from '../components/project-segments/virtualsegmentation/VueOne'
import VueTwo from '../components/project-segments/virtualsegmentation/VueTwo'
import CssOne from '../components/project-segments/virtualsegmentation/CssOne'
import IllustratorOne from '../components/project-segments/virtualsegmentation/IllustratorOne'

export default {
    name: 'VirtualSegmentation',

    components: {
        NavBar, ProjectHeading, TechLogo, SegmentWrapper, Foot,
        PhotoshopOne, PhotoshopTwo, VueOne, VueTwo, CssOne, IllustratorOne
    },

    data () {
      return {
      }
    },

    created() {  
      document.title = 'Virtual Segmentation'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
