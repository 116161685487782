<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/misc/booking-form.png')" alt="Booking form" width="519" height="312" /></p>

      <p>The datepicker was a 3rd party module, but it didn't make sense to install yet more 3rd party code for the time selection.</p>

      <p>As the pub opened from midday, I thought the time select dropdown (at 15 minute intervals) was not going to get ridiculously long as a single
        field. The main objectives were to:
        <ol>
            <li>Not allow someone to book a time in the past, even when a date in the future is selected and then reverted to today's date</li>
            <li>Display less hours on a Sunday to match opening times</li>
        </ol>
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
<c-datepicker name="date" inline="true" :disabled-dates="disabledDates" @selected="dateSelected"></c-datepicker>
<c-input name="time" label="Time" type="select" :options="selectedDay == 0 ? bookingTimes.slice(0, -2) : bookingTimes"></c-input>

data() {
    return {
        newDate: new Date(),
        bookingTimes: [],

        times: [
            '12:00','12:15','12:30','12:45',
            ...
        ]
    }
},

methods: {
    dateSelected(payload) {
        let self = this;
        self.selectedDay = payload.getDay();
        self.buildTimes(payload);
    },

    addTime(time) {
        let self = this;
        time = time.substr(0,2) + ':' + time.substr(2);
        let option = { value: time, text: time };
        self.bookingTimes.push(option);
    },

    buildTimes(selectedDate) {
        let self = this;
        let hours = selectedDate.getHours();
        let minutes = selectedDate.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let time = hours + '' + minutes;
        let todaySelected = (selectedDate.toDateString() === self.newDate.toDateString()) ? true : false;

        self.bookingTimes = [];
        self.times.forEach(function(i) {
            i = i.replace(':','');
            if (todaySelected) {
                if (parseInt(i) > parseInt(time)) {
                    self.addTime(i);
                } else {
                    self.bookingTimes = [];
                }
            } else {
                self.addTime(i);
            }
        });
    },

mounted() {
    let self = this;
    self.buildTimes(self.newDate);
}`
    }
  }
}
</script>

<style lang="scss">
#misc-vue-one .text-wrap img {
  box-shadow: none;
}
</style>