<template>
  <segment-wrapper category="vue">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>As well as this, I had other tasks to identify hooks and make sure the user was fed
        the right infomation and given the right options, whether that be a software update
        where a user was required to restart the system, or guiding the user when what they
        were tring to enter was incorrect/could cause harm to the system.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<form @submit.prevent="changeLinkName(cm.linkName)">
  <div class="field">
    <div class="control">
      <input v-validate="'required'" name="linkname" v-model="cm.linkName" :class="{ 'is-danger': errors.has('linkname')}"/>
      <small v-show="errors.has('linkname')" class="field-text tx-danger">{{ errors.first('linkname') }}</small>
    </div>
  </div>
  <div class="buttons">
    <button type="submit" :disabled="errors.has('linkname')" v-close-popover>Save</button>
    <a class="button is-small is-link" v-close-popover @click="revertChange">Close</a>
  </div>
</form>

changeLinkName(linkName) {
  this.isLoading(true);
  this.updateLinkName({name: linkName})
    .then(() => {
      this.msgSuccess({text: 'Link name changed'});
    })
    .catch(err => {
      console.log(err);
      this.msgError({text: 'Error updating link name'});
    })
    .finally(() => {
      this.isLoading(false);
    })
}`
    }
  },
}
</script>
