<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="colours" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Initially there were only 3 existing corporate blues (top left),
        and two greyscale colours (bottom right). I built from these, the two blue variations featured 
        top right being added much later for use in the page content, following the request for coloured
        backgrounds to be given to certain headers. I produced these as felt they stood in contrast with
        the stronger hued blues used in the software frame, but complemented them at the same time.
        The palette contains info on naming conventions and hex values.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      colours: [
        {
          thumb: 'images/txpatterns/colours.jpg',
          description: 'Technetix extended colour pallette',
          width: 1118,
          height: 340
        },
      ]
    }
  },
}
</script>
