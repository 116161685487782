<template>
  <segment-wrapper category="vue" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Upon successful registration, the user is forwarded to the other blade file that handles 
        the questionnaire itself. Session storage holds the user id and answers to each question - 
        answers in plural as the questionairre  requires the possibility to have more than 1 answer 
        to a question.</p>

      <p>This meant the radio button styling had to be on checkboxes, and the values of the selected 
        items had to be captured in a list, as opposed to simply a single boolean value.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<input type="checkbox" class="radio" @change="questionairre.trim(questionairre.current_question.chosen_answers)" :name="questionairre.current_question.question" v-model="questionairre.current_question.chosen_answers" :value="answer.id">

moveToNext() {
  let self = this;
  sessionStorage.setItem('question-' + self.current_question_index, self.current_question.chosen_answers);

  if ((self.current_question_index + 1) < self.questions.length) {
    if (self.current_question.chosen_answers.length == self.questions[self.current_question_index].answer_count) {
      self.current_question_index++;
    }
  } else {
    self.complete();
  }
},
`
    }
  }
}
</script>
