<template>
  <div class="project-page darkstar">
    <nav-bar/>
    <project-heading backgroundColour="#000000">
      <template v-slot:icon>
        <img :src="require('../assets/images/home/darkstar.svg')" alt="Dark Star Brewing Company" />
      </template>

      <template v-slot:summary>
        <li>Extended theming in Shopify CLI, including custom Sass Webpack compiling to match Shopify's efficient style calling</li>
        <li>Custom Sections with fully dynamcic repeatable blocks controlled through CMS</li>
        <li>Custom product themes introduced via Product Metafields</li>
        <li>Scroll animations using Javascript GSAP/Scrolltrigger</li>
        <li>Git workflow added through Shopify Github integration so any small changes are fully tracked</li>
      </template>
    </project-heading>

    <photoshop-one />
    <shopify-one />
    <css-one />
    <shopify-five />
    <shopify-two />
    <shopify-three />
    <shopify-four />
    <javascript-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/darkstar/PhotoshopOne'
import ShopifyOne from '../components/project-segments/darkstar/ShopifyOne'
import CssOne from '../components/project-segments/darkstar/CssOne'
import ShopifyTwo from '../components/project-segments/darkstar/ShopifyTwo'
import ShopifyThree from '../components/project-segments/darkstar/ShopifyThree'
import ShopifyFour from '../components/project-segments/darkstar/ShopifyFour'
import ShopifyFive from '../components/project-segments/darkstar/ShopifyFive'
import JavascriptOne from '../components/project-segments/darkstar/JavascriptOne'

export default {
    name: 'DarkStar',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, ShopifyOne, CssOne, ShopifyTwo, ShopifyThree, ShopifyFour, ShopifyFive, JavascriptOne
    },

    created() {  
      document.title = 'Dark Star Brewing Company Ecommerce Solution'  
      document.head.querySelector('meta[name=description]').content = 'Dark Star Brewing Company Ecommerce solution using Shopify CLI'
    }
  }
</script>
