<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="ui" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Page components help illustrate items typically used to structure a page and its content. 
        As Technetix’s software is typically used to monitor networks and compare metrics, special 
        attention is brought to tables and tabs as these tend to feature regularly across software.
        Tables that contain form fields don’t feature the odd/even backgrounds as it was felt 
        additional visual weight was being given to the rows with darker backgrounds. Generic 
        data didn’t seem to suffer from this problem.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopThree',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      ui: [
        {
          thumb: 'images/txpatterns/page-components-1.jpg',
          description: 'UI Components',
          width: 1118,
          height: 600
        }
      ]
    }
  },
}
</script>
