<template>
  <div class="home">
    <section id="landingpane" class="hero">
        <nav-bar/>
        <div></div>
        <div class="intro">
            <div class="focal">
                <div>UI</div>
            </div>
            <h1>Design &amp; <br>Development</h1>
            <!-- <p>benwain.info is a selection of recent work, outlining the processes and the technologies behind them. Projects vary from hardware-accompanying software solutions through to e-commerce websites, from both design and development roles.</p> -->
            <p>benwain.info is an introduction to my work, outlining the design and development processes and the technologies behind them. Projects vary from hardware-accompanying software through to e-commerce solutions. <a class="decorative" href="mailto:mail@benwain.info">Get in touch.</a></p>
        </div>
        <div class="project-list">
            <Tile name="NHS Safeguarding Tool" type="Web Tool" route="/nhs-safeguarding" tileClass="nhs" />
            <Tile name="Dark Star Brewing" type="Ecommerce Website" route="/darkstar" tileClass="darkstar" />
            <Tile name="Frogg" type="Brochure Website" route="/frogg" tileClass="frogg" />
            <!-- <Tile name="Bathe" type="Brochure Website" route="/bathe" tileClass="bathe" /> -->
            <!-- <Tile name="Virtual Segmentation" type="Desktop Software" route="/virtualsegmentation" tileClass="vs" /> -->
            <Tile name="Asahi Contractor Onboarding" type="Web Application" route="/asahi" tileClass="asahi" />
            <Tile name="Meantime Brewing Company" type="Ecommerce Website" route="/meantime" tileClass="meantime" />
            <Tile name="Technetix Software Patterns" type="Pattern Library" route="/txpatterns" tileClass="tx-patterns" />
        </div>
        <div></div>
    </section>
    <foot />
    <!-- <section id="secondpane" class="below-fold">
        <div class="home-left">
            <h1><span>Ben Wain</span> UI Design/Development. 15+ years industry experience in a range of technologies.</h1>
        </div>
        <div class="home-right" :class="hoverBg">
            <div class="logo-pool">
                <tech-logo v-for="(item, id) in items" :logo="item" :key="id" :projectPage="false" @mouseover.native="logoHover(item.meta.colour)" @mouseleave.native="logoHoverOff" />
            </div>
        </div>
        
        <foot />
    </section>

    <div class="scroll-controls">
        <div class="inner">
            <button class="button" v-scroll-to="'#secondpane'"><img class="slide-top" :src="require('@/assets/images/chevron-down-solid.svg')" alt="" width="16" height="19" /></button>
            <button class="button" v-scroll-to="'#landingpane'"><img class="slide-bottom" :src="require('@/assets/images/chevron-up-solid.svg')" alt="" width="16" height="19" /></button>
        </div>
    </div> -->
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import Tile from '../components/ProjectTile'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'Home',

    components: {
        NavBar, Tile, TechLogo, Foot
    },

    data () {
        return {
            items: this.$router.options.routes[1].children,
            hoverBg: '',
        }
    },

    created() {  
        document.title = 'Ben Wain UI/Website Design/Development'
        document.head.querySelector('meta[name=description]').content = 'UI Design and Development Portfolio by Ben Wain. Website, App, Software Design and Front-end Development.'
        document.head.querySelector('meta[name=robots]').content = 'all'     
    },

    // methods: {
    //     logoHover(color) {
    //         this.hoverBg = color + '-bg'
    //     },

    //     logoHoverOff() {
    //         this.hoverBg = ''
    //     }
    // },

    mounted() {
        let landingScreen = document.getElementById('landingpane')
        // let belowFold = document.getElementById('secondpane')
        // let scrollButtons = document.querySelector('.scroll-controls')
        orientation()

        document.addEventListener("DOMContentLoaded", function() {
            orientation()
        });

        function orientation() {
            let getWidth = window.innerWidth
            let getHeight = window.innerHeight
            let landingHeight = landingScreen.getBoundingClientRect().height

            if (getWidth > (getHeight + 200) && landingHeight <= getHeight) {
                landingScreen.classList.add('is-fullheight')
                // belowFold.classList.add('is-fullheight')
                // scrollButtons.classList.remove('is-hidden')
            } else {
                // scrollButtons.classList.add('is-hidden')
                landingScreen.classList.remove('is-fullheight')
                // belowFold.classList.remove('is-fullheight')
            }
        }

        //window.addEventListener('resize', orientation())

        // function userScroll() {
        //     let landingHeight = landingScreen.getBoundingClientRect().height
        //     let winScroll = document.body.scrollTop || document.documentElement.scrollTop
        //         if (window.innerWidth > 1185) {
        //             if (winScroll > 0 && landingHeight > winScroll) {
        //                 scrollButtons.classList.add('fade')
        //             } else {
        //                 scrollButtons.classList.remove('fade')
        //                 if (winScroll > 0){
        //                     scrollButtons.classList.add('top')
        //                 } else {
        //                     scrollButtons.classList.remove('top')
        //                 }
        //             }
        //         } else {
        //             if (winScroll > 0) {
        //                 scrollButtons.classList.add('fade')
        //             } else {
        //                 scrollButtons.classList.remove('fade')
        //             }
        //         }
        //     }

        //     window.onscroll = function() {
        //         userScroll()
        //     }

            // ScrollTrigger.defaults({
            //     toggleActions: "play none none reverse"
            // })

            // gsap.from('.home-left h1', {scrollTrigger: '.home-left h1', duration: 1.4, opacity: 0, x: 20})
            // gsap.to('.home-left h1', {opacity: 1, x: 0})
        }
    }
</script>

<style lang="scss">

#landingpane {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //display: grid;
    //grid-template-rows: min-content 1fr min-content min-content 1fr;
    
    .is-fullheight {
        height: auto;
    }

    .intro {
        gap: 1rem;
        margin-bottom: 2rem;
        margin-top: auto;

        .focal {
            line-height: 1;
            font-weight: 300;

            div {
                font-family: "Muli", sans-serif;
                background: -webkit-linear-gradient(#000, #000 20%, #5e2f9b 70%, #0000ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                line-height: 1;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $midblue;
            }
        }
    }

    .project-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: auto;
    }
}

// .tile.is-ancestor {
//     margin: auto;
//     width: 100%;
// }

// .scroll-controls {
//     margin: 0 auto;
//     left: 0;
//     right: 0;
//     height: 5rem;
//     max-height: 5rem;
//     position: fixed;
//     bottom: -2.5rem;
//     transform: rotate(45deg);
//     transition: all .5s;
//     width: 5rem;
//     max-width: 5rem;
//     z-index: 2;

//     &.top {
//         top: -2.5rem;
//     }

//     &.fade {
//         opacity: 0;
//         pointer-events: none;
//     }

//     .inner {
//         text-align: center;
//         transform: rotate(-45deg);
//     }

//     .button {
//         background: transparent;
//         border-color: transparent;
//         height: 2.5rem;
//         padding: 0;
//         width: 100%;

//         svg {
//             fill: $purple;
//         }

//         &:hover {
//             border: none;
//         }
//     }

//     button:focus, .button:focus, .button:focus:not(:active) {
//         box-shadow: none;
//         outline: none;
//     }
// }

// #secondpane {
//     position: relative;
// }

// .home-left {
//     display: flex;

//   h1 {
//       font-family: "Hind", sans-serif;
//       align-self: center;
//       color:$purple;
//   }

//   span {
//       color: lighten($purple, 30%);
//   }
// }

// .home-right {
//     padding: 2rem 2rem 3rem;
//     background: $purple;
//     display: flex;
//     transition: .5s all;
// }

// .logo-pool {
//     display: flex;
//     flex-wrap: wrap;
//     margin: auto;
//     max-width: 37.5rem;

//     svg {
//         fill: #eee;
//     }
// }

@media screen and (max-width: $tablet - 1px) {

    #landingpane {
        padding-right: 1rem;
        padding-left: 1rem;

        .intro {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: .5rem;

            .focal div {
                font-size: 5rem;
            }

            h1 {
                padding-top: 5px;
                font-size: 1.8rem;
                line-height: 1.3;
            }

            p {
                grid-column-end: span 2;
                max-width: 440px;
            }
        }
    }

    // .home-left {
    //     padding: 1rem 1rem 1.5rem;

    //     h1 {
    //         font-size: 1.2rem;
    //         padding: 1rem 1rem .5rem;
    //         margin: 0 auto;
    //         max-width: 21rem;
    //     }
    // }

    // .home-right {
    //     padding: 2rem 0;
    // }

    // .scroll-controls {
    //     display: none;
    //     visibility: hidden;
    //     pointer-events: none;
    // }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {

    #landingpane {
        padding: 0 2rem 0;

        &.is-fullheight {
            padding-bottom: 4rem;
        }
    }

    // .home-left {
    //     h1 {
    //         font-size: 1.5rem;
    //         padding-bottom: 3rem;
    //         max-width: 31rem;
    //     }
    // }
}

@media screen and (min-width: $tablet) {
    #landingpane {
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;

        .intro {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-template-rows: max-content 1fr;
            margin-bottom: 3rem;

            .focal {
                grid-row-end: span 2;

                div {
                    font-size: 170px;
                }
            }

            h1 {
                padding-top: 7px;
                letter-spacing: -.125rem;
                font-size: 48px;
                white-space: nowrap;
                margin: 0;

                br {
                    display: none;
                }
            }

            p {
                grid-column-start: 2;
                column-count: 2;
                gap: 2rem;
            }
        }
    }

    // .home-left h1 {
    //     margin: auto;
    // }
}

@media screen and (max-width: $desktop - 1px) {

    // .scroll-controls.fade {
    //     height: 0;
    //     max-height: 0;
    //     width: 0;
    //     max-width: 0;

    //     .inner, .inner button {
    //         display: none;
    //         width: 0;
    //         height: 0;
    //         position: absolute;
    //     }
    // }
}

@media screen and (min-width: $desktop) {

    #landingpane {
        padding-bottom: 3rem;



        .project-list {
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
        }
    }

    // #secondpane {
    //     display: flex;

    //     &.is-fullheight {
    //         min-height: 100vh;
    //     }
    // }

    // .home-left,
    // .home-right {
    //     flex-basis: 50%;
    // }

    // .home-left {
    //     background: #ECE9E9;
    
    //   h1 {
    //       max-width: 30rem;
    //       font-size: 2rem;
    //     }
    // }

    // .scroll-controls {
    //     background: $white;
    // }
}

@media screen and (max-width: $fullhd - 1px) {

    #landingpane {
        max-width: 880px;
    }
}

@media screen and (min-width: $fullhd) {

    #landingpane {
        padding: 0 5rem 5rem;

        .intro {
            gap: 2rem;
            margin-bottom: 4rem;

            .focal div {
                font-size: 262px;
            }

            h1 {
                font-size: 66px;
                padding-top: 13px;
            }
        }
    }

    // .home-left {
    //     padding: 5rem;

    //     h1 {
    //         font-size: 2.5rem;
    //         letter-spacing: -.0625rem;
    //         max-width: 37.5rem;
    //     }
    // }

    // .logo-pool {
    //     max-width: 31.5vw;
    // }
}
</style>
