<template>
  <segment-wrapper category="magento" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>Additional field values were added to the admin interface to prevent .phtml 
        files becoming too weighty, as well as allowing updates without requiring a 
        full redeploy in future.</p>

      <p>During the work, I had to update the database several times, including renaming 
        certain columns. I had to remember to keep the updateData setup file containing 
        all the required information for colleagues updating their databases from scratch 
        when the work was ready to push.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'MagentoFour',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
public function upgrade(ModuleDataSetupInterface $setup, ModuleContextInterface $context)
  {
    if (version_compare($context->getVersion(), '1.0.9', '<')) {
      $eavSetup = $this->eavSetupFactory->create(['setup' => $setup]);

      $eavSetup->addAttribute(\Magento\Catalog\Model\Category::ENTITY, 'brand_pfe_rating', [
        'type'     => 'int',
        'label'    => 'PFE rating',
        'input'    => 'select',
        'visible'  => true,
        'default'  => '0',
        'required' => false,
        'global'   => \Magento\Eav\Model\Entity\Attribute\ScopedAttributeInterface::SCOPE_STORE,
        'group'    => 'general'
      ]);

      $eavSetup->updateAttribute(\Magento\Catalog\Model\Category::ENTITY,
          'brand_interim_head_block',
          'attribute_code',
          'brand_header_block');
        }
      }`
    }
  },
}
</script>
