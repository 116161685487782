<template>
  <segment-wrapper category="photoshop" >
    <template v-slot:media>
      <image-layout :images="productPage" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>Vehicle listings and details pages were given a more purposeful layout, with information
        grouped cleanly and the heading size hierarchy really having an impact. Icons were used
        for the first time against vehicle spec items, making this section more digestible.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopFour',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      productPage: [
        {
          thumb: 'images/advanced4/vehicle-result.png',
          description: 'Stock listing',
          fullsize: 'images/advanced4/vehicle-result.png',
          width: 533,
          height: 270
        },
        {
          thumb: 'images/advanced4/vehicle-details.jpg',
          description: 'Stock details',
          fullsize: 'images/advanced4/vehicle-details.jpg',
          width: 553,
          height: 319
        }
      ]
    }
  },
}
</script>
