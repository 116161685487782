<template>
  <segment-wrapper category="sass" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="css"></code-snippet>
    </template>

    <template v-slot:text>
      <p>With colour rules being passed between components, it proved easiest to set a default colour
        of transparent, then using a class naming convention to set the correct colour against the
        element. SCSS removed the repetitive strain of the task.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'SassOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
$colours: (
  white: $white,
  purple: $purple,
  pink: $pink,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  skyblue: $skyblue,
  midblue: $midblue,
  duskblue: $duskblue,
);

@each $name, $value in $colours {
  .#{$name} {
      color: $value;
  }
  .#{$name}-bg {
      background-color: $value !important;
  }
  .#{$name}-border {
      border-color: $value;
  }
}`
    }
  },
}
</script>
