<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="Javascript"></code-snippet>
    </template>

    <template v-slot:text>
		<p><img :src="require('@/assets/images/nhs/safeguard-results.png')" alt="Advice results tiles" width="431" height="258" /></p>

      <p>In addition the post ID’s being used to steer the user through the questions, not setting a next 
		tile to show in the admin interface meant the code would assume it was at the end of the questions stage.</p>

	  <p>In the background, and array had been building a list of ID’s of advice tiles that had been flagged. 
		When the end was reached, a second call is sent to the API with a list of responses to display. 
		These were shown as ‘accordion’ items so the user can have an overview of the feedback and 
		expand where necessary. </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'WordpressOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
let feedbackTileIds = [];

function getResults() {
	if (feedbackTileIds.length > 0) {
		loader.classList.add('is-loading');
		fetch('/wp-json/wp/v2/tiles?include=' + feedbackTileIds, {
			method: 'GET',
		})
		.then( data => data.json() )
		.then( data => {
			constructResults(data);
			loader.classList.remove('is-loading');
		});
	} else {
		constructResults(0);
	}
}

function navigate(e) {
	selectedId = document.getElementById(e.target.dataset.link);
	tileId = e.target.dataset.tileid;

	if (tileId != '' && tileId != 'undefined') {
		feedbackTileIds.push(tileId);
	}

	if (e.target.dataset.link != '') {
		...
	} else {
		cardWrapper.classList.add('complete');
		skipButton.classList.add('is-hidden');
		getResults();
	}
}
`
    }
  },
}
</script>
<style lang="scss">
.nhs {
  #javascript-two {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #nhs-javascript-two {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>