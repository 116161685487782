<template>
  <segment-wrapper category="photoshop">
    <template v-slot:media>
      <image-layout :images="screens" arrange="align-center" />
    </template>

    <template v-slot:text>
      <p>Virtual Segmentation Software had been started by a Tiwan-based company which had gone bust.
        It was another example of software without any design or branding, and had information collated 
        in a very confusing manner. Our first task was to understand how the hardware worked and re-organise the data to be 
        presented logically to the end user, and implement the Pattern Library I had been working on.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      screens: [
        {
          thumb: 'images/virtualsegmentation/system-information-old.png',
          description: 'Old homepage',
          class: 'browser-hs',
          width: 503,
          height: 241
        },
        {
          thumb: 'images/virtualsegmentation/system-information.jpg',
          description: 'New homepage',
          class: 'browser-hs',
          width: 503,
          height: 256
        }
      ]
    }
  },
}
</script>
