<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>On loading the page, all the questions were looped  through, cards constructed and set to hidden.</p>

	    <p>I used the ID of the posts to determine which card to show next by giving each the relevant post Id and 
		passing which card to display next as a data-attribute on the link, captured by the Advanced Custom Field.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
function constructCards(data) {
	cardWrapper = document.querySelector('.card-wrapper');

	for (var i = 0; i < data.length; i++) {
		let id = data[i].id;
		let card = '<div class="card" id="' + id + '">';
		card += '<header class="spc--mb2">' + data[i].title.rendered + '</header>';
		card += '<div>' + data[i].content.rendered + '</div>';
		
		if (data[i].acf.button_collection != null) {
			card += '<div class="btn__group">';
			let nextCard = data[i].acf.next_card;

			data[i].acf.button_collection.forEach(function(item, index) {
				let tileId = item.response_tile;
				let type = item.button_type;
				let button = '<button class="btn btn-' + type.toLowerCase() + ' question-btn" data-link="' + nextCard + '" data-tileid="' + tileId + '">' + type + '</button>';

				card += button;
			})

			card += '</div>';
		}
		card += '</div>';
		cardWrapper.innerHTML += card;
	}
  ...
}

function navigate(e) {
  selectedId = document.getElementById(e.target.dataset.link);
  tileId = e.target.dataset.tileid;

  if (e.target.dataset.link != '') {
    if (selectedId != null ) {
      let currentlyActive = document.querySelector('.active');
      currentlyActive.classList.remove('active');
      selectedId.classList.add('active');
    }
  }
  ... 
}
`
    }
  }
}
</script>
