<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <southeastbike-drupal-one />
    <southeastbike-drupal-two />
    <southeastbike-drupal-three />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import SoutheastbikeDrupalOne from '../../components/project-segments/southeastbike/DrupalOne'
import SoutheastbikeDrupalTwo from '../../components/project-segments/southeastbike/DrupalTwo'
import SoutheastbikeDrupalThree from '../../components/project-segments/southeastbike/DrupalThree'

export default {
    name: 'Drupal',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        SoutheastbikeDrupalOne, SoutheastbikeDrupalTwo, SoutheastbikeDrupalThree
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Drupal'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
