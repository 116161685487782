<template>
  <segment-wrapper category="jquery" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/misc/basket-lg.png')" alt="Sub-checkboxes" width="529" height="226" /></p>

      <p>Scenario: A user can select additional services against a product in the basket.
        For example the installation of an appliance. Within this option there are terms to be
        agreed, so if a user ticks the service, the checkout button needs to disable until
        either all terms are checked, or user deselects that option.</p>

      <p>There can be multiple services selected, a user can navigate away and back to the 
        basket, and suddenly there are a lot of edge cases to consider.</p>

      <p>I found checking against an object on each click was unreliable, so had to create a 
        separate array just for checking if there were any falsys in the selected options. The 
        object was then used for local storage to preserve the user's selections.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JqueryOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
$(document).ready(function() {
  setSubcheckOptions();
  optionDescriptions();
});

function hasDescriptionSubchecks() {
  var hasDescription = document.querySelectorAll('.has-description');
  $(hasDescription).each(function(index, value) {
    if ($('#' + value.id).is(':checked')) {
      var descriptionId = value.id + '_description';
      var hasSubChecks = $('#' + descriptionId + ' input').length;
      return hasSubChecks;                        
    }
  });
};
  
function optionDescriptions() {
  var hasSubChecks = hasDescriptionSubchecks();
  if (hasSubChecks > 0) {
    checkSubInputs();
  }                            
}
  
function checkSubInputs() {
  var subcheckCollection = [];
  var subcheckStates = [];
  var subCheckStore = [];
  var hasSubChecks = hasDescriptionSubchecks();
  if (hasSubChecks) {
      subcheckCollection.push(descriptionId);
  }

  $.each(subcheckCollection, function(index, value) {
    $('#' + value + ' input').each(function(index, value) {
      subcheckStates.push($('#' + value.id).is(':checked'));
              
      var storeItem = {
        id: value.id,
        checked: $('#' + value.id).is(':checked'),
      };
      subCheckStore.push(storeItem);
      customerData.set('subcheck-options', subCheckStore);
    });
  });
    
  if (subcheckStates.includes(false)) {
    $('.checkout.methods .action.primary').attr('disabled', true);
  } else {
    $('.checkout.methods .action.primary').attr('disabled', false);
  }
}

function setSubcheckOptions() {
  var subcheckOption = customerData.get('subcheck-options')();
  if (subcheckOption.length > 0) {
    $.each(subcheckOption, function(index, value) {
      $('#' + value.id).prop('checked', value.checked);
    });
  };
};
    
$(document).on('change', '[name*="options"]', function(){
    if ($(this).hasClass('has-description')) {
    checkSubInputs();
  }
});
  
$(document).on('change', '.option-description input', function(){
  optionDescriptions();
});`
    }
  },
}
</script>
