<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/meantime/event-frontend.jpg')" alt="Event List" width="519" height="197" /></p>

      <p>One of the requirements that didn't have anything suitable was for the customer to add events to a list on their Taproom page.</p>

      <p>By adding a custom block type of 'event' within the event list section, the customer was able to add and remove events via a
        dynamic repeater field. If the link button was not used, the description would fill in the void.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- for block in section.blocks -%}
    <div class="event animin fadeleft target-children">
        <div class="event-visual">
            <div class="media media--square">
                {%- if block.settings.image != blank -%}
                <img
                    src="{{ block.settings.image | image_url }}"
                    alt="{{ block.settings.heading }}"
                    width="100%"
                    loading="lazy"
                >
                {%- endif -%}
            </div>
        </div>

        <div class="event-info">
            <div class="event-textual">
                <h3>{{ block.settings.heading }}</h3>
                <p class="rich-text__caption">{{ block.settings.date | escape }}</p>
                {{ block.settings.text }}
            </div>

            {%- if block.settings.button_label != blank -%}
            <div class="more-info">
                <a{% if block.settings.button_link == blank %} role="link" aria-disabled="true"{% else %} href="{{ block.settings.button_link }}"{% endif %} class="button button--secondary">{{ block.settings.button_label | escape }}</a>
            </div>
            {%- endif -%}
        </div>
    </div>
{%- endfor -%}
`
    }
  },
}
</script>

<style lang="scss">
.meantime {
  #shopify-two {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #meantime-shopify-two {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>