<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/darkstar/image-banner.jpg')" @load="imagesLoaded()" alt="Dark Star branding" /></p>

      <p>A lot of modifications were needed for the existing components too. For example, a foreground image featured in a few locations so additional 
        fields were added in the admin interface to handle this.</p>
      
      <p>The content container overlaying the slide was set up in a way that meant the image 
        width calculation could not be performed properly. </p>
      
      <p>To fix this, the parent container had to have padding removed and instead added to each child item individually.</p>
      
      <p>As this item was prominent on the site, it also needed to set to display none inline, until the stylesheet had loaded to 
        avoid a small jump visible on slower connections.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyFive',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- case block.type -%}
  {%- when 'image' -%}
    {%- style -%}
    @media screen and (max-width: 749px) {
      .foreground-image {
        width: {{ block.settings.width_mobile }};
      }
    }

    @media screen and (min-width: 750px) {
      .banner .banner__content .banner__box {
        min-width: {{ block.settings.width_desktop }};
      }
    }

    .foreground-image img {
      display: none;
    }
    {%- endstyle -%}
    <div class="foreground-image">
      <img class="animin" src="{{ block.settings.foreground_image | image_url }}">
    </div>
...

.banner {
	.banner__content {
		.banner__box {

			> * {
				padding-right: 3.5rem;
				padding-left: 3.5rem;
      }

      .foreground-image {
        img {
          display:block !important;
        }
      }
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.darkstar {
  #shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #darkstar-shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>