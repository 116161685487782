<template>
  <segment-wrapper category="magento" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>The individual template files all followed a similar structure; this would allow 
        quick initial production and the flexibility to customise in the future. 
        Common style rules were set in the main .less file, whilst overriding individual 
        styles were set in the head of the .phtml file.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'MagentoThree',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<style>
@media only screen and (max-width: 767px) {
  .canagan-brand-hero {
    background-image: url(<?php echo $imgUrl; ?>canagan-bg-mobile.jpg);
  }
}
...
</style>

<section class="bg--image brand-hero canagan-brand-hero">
  <div class="v-container spc--pt3 spc--mb3--3">
    <div class="v-row spc--mt3">
      <div class="v-col--33--3 v-col--37--4 hidden__1t2e spc--pt1 rel">
        <?php if (!empty($brand['recycle_icon'])) { ?>
          <img class="nolazy recyclable" src="<?php  /* @escapeNotVerified */ echo $imgUrl . $brand['recycle_icon']; ?>" alt="Recyclable packaging">
        <?php } ?>
        ...`
    }
  },
}
</script>
