<template>
  <segment-wrapper category="jquery">
    <template v-slot:media>
      <image-layout :images="slickImages" arrange="match-width" />
    </template>

    <template v-slot:text>
      <p>I also used the Jquery carousel library ‘Slick’ as this was used in our online shops.
        In the Portfolio page, I paired two galleries together, and swapped out controls for
        different devices. Using the ‘responsive’ method provided by slick proved buggy for
        the controls, so this also relied on CSS to swap controls based on viewport.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JqueryTwo',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      slickImages: [
        {
          thumb: 'images/bathe/slick-desktop.jpg',
          description: 'Slideshow controls on desktop',
          class: 'browser-hs',
          width: 804,
          height: 483
        },
        {
          thumb: 'images/bathe/slick-mobile.jpg',
          description: 'Slideshow controls on mobile',
          class: 'browser-hs',
          width: 250,
          height: 521
        }
      ]
    }
  },
}
</script>
