<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <vs-illustrator-one />
    <southeastbike-illustrator-one />
    <southeastbike-illustrator-two />
    <a4-illustrator-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import VsIllustratorOne from '../../components/project-segments/virtualsegmentation/IllustratorOne'
import SoutheastbikeIllustratorOne from '../../components/project-segments/southeastbike/IllustratorOne'
import SoutheastbikeIllustratorTwo from '../../components/project-segments/southeastbike/IllustratorTwo'
import A4IllustratorOne from '../../components/project-segments/advanced4/IllustratorOne'

export default {
    name: 'Illustrator',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        VsIllustratorOne, A4IllustratorOne, SoutheastbikeIllustratorOne, SoutheastbikeIllustratorTwo
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Illsutrator'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
