<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/meantime/product-page.jpg')" @load="imagesLoaded()" alt="Product theming" width="495" height="522" /></p>

      <p>Some feature products had individual theming, like colour, logo and pattern background. 
        Initially I was going to add this conditionally based on product ID, but discovering 
        product meta and how easily it was accessed in other site areas like Product Cards I 
        added all the fields via the meta fields. 
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyThree',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- style -%}
  {%- if product.metafields.custom.product_theme_colour -%}
    .page-product .product-form__submit.button {
      background: #{{ product.metafields.custom.product_theme_colour }};
    }

    .page-product .button:not([disabled]):not(#Subscribe):hover::after {
      box-shadow: 0 0 0 4px #{{ product.metafields.custom.product_theme_colour }};
    }

    .page-product .hex-bullet {
      fill: #{{ product.metafields.custom.product_theme_colour }};
    }
  {%- endif -%}
{%- endstyle -%}

{%- if product.tags contains 'format_CANS' or product.tags contains 'format_BOTTLES' -%}
<section class="themed-description" style="{%- if product.metafields.custom.theme_background_pattern -%} background-image: url({{ product.metafields.custom.theme_background_pattern | image_url }}); background-repeat: repeat;{%- endif -%}{%- if product.metafields.custom.product_theme_colour -%} background-color: #{{ product.metafields.custom.product_theme_colour }};{%- endif -%}">
  <section class="page-width animin fadeup target-children">
    {%- if product.metafields.custom.product_icon -%}
      <img loading="lazy" src="{{ product.metafields.custom.product_icon | image_url }}" height="200" alt="">
    {%- endif -%}
    <h2 class="h1">{{ product.title | escape }}</h2>
    {%- if product.description != blank -%}
    <div class="content">
      {{ product.description }}
    </div>
    {%- endif -%}
  </section>
</section>
{%- endif -%}`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
// .meantime {
//   #shopify-three {
//     .text-wrap {
//       img {
//         box-shadow: none;
//       }
//     }
//   }
// }
</style>