<template>
  <div class="tech-page empty-page">
    <nav-bar/>
    <tech-heading />

    <segment-wrapper category="bootstrap" orientation="intro">
      <template v-slot:text>
        <p>There are no examples of Tailwind CSS on my portfolio currently. My commissioned work however is based on this platform so I am well rehearsed in using this framework.</p>
      </template>
    </segment-wrapper>

    <!-- <a4-photoshop-one /> -->

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import SegmentWrapper from '../../components/SegmentWrapper'
import Foot from '../../components/Foot'

//import A4PhotoshopOne from '../../components/project-segments/advanced4/PhotoshopOne'

export default {
    name: 'Tailwind',

    components: {
        NavBar, TechHeading, TechLogo, SegmentWrapper, Foot,
        //A4PhotoshopOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Tailwind CSS'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
