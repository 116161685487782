<template>
  <div class="foot">
    <div class="left">
      <span>mail@</span>benwain<span>.info</span><br />
      &copy; {{ year() }}
    </div>

    <div>
      <router-link v-if="currentRouteName != 'Home'" :to="'/'" class="home-button">
        <svg width="27" height="24" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
      </router-link>
    </div>

    <router-link :to="'/about'" class="right">Handmade <span>with</span><br /> 
      <svg width="1.5rem" viewBox="0 0 128 128">
        <path d="m-2.3125e-8 8.9337 49.854 0.1586 14.167 24.47 14.432-24.47 49.547-0.1577-63.834 110.14zm126.98 0.6374-24.36 0.0207-38.476 66.052-38.453-66.052-24.749-0.0194 63.211 107.89zm-25.149-0.008-22.745 0.16758l-15.053 24.647-14.817-24.647-22.794-0.1679 37.731 64.476zM25.997 9.3929l23.002 0.0087M25.997 9.3929l23.002 0.0087" fill="none"></path><path class="vue-inner" d="m25.997 9.3929 23.002 0.0087l15.036 24.958 14.983-24.956 22.982-0.0057-37.85 65.655z"></path><path class="vue-outer" d="m0.91068 9.5686 25.066-0.1711 38.151 65.658 37.852-65.654 25.11 0.0263-62.966 108.06z"></path>
      </svg>
      <span>Vue and</span>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.05rem" height="1.68rem" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 373"><path class="bulma" d="M0 256L23.273 93.09L116.363 0l116.364 116.364l-69.818 69.818L256 279.272l-139.636 93.092z"/></svg>
      <span> Bulma</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Foot',

  methods: {
    year() {
      let d = new Date()
      return d.getFullYear()
    }
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  } 
}
</script>

<style lang="scss">
.foot {
  color: rgba(0,0,0,.4);
  display: flex;
  margin-top: 2rem;
  min-height: 3.55rem;
  position: relative;
  padding: .5rem 1rem;
  overflow: hidden;

  svg:not(.fa-home) {
    margin-left: .35rem;
    margin-right: .15rem;
    margin-top: -.45rem;
    position: relative;
    top: .45rem;
  }

  > div {
    margin-top: auto;
    flex-basis: 33.33%;
  }

  > a {
    color: rgba(0,0,0,.4);
    text-align: right;
    margin-left: auto;
    margin-top: auto;
  }

  .home-button {
    border-radius: $radius $radius 0 0;
    background: rgba(0,0,0,.05);
    display: block;
    margin: 0 auto;
    height: 4rem;
    position: relative;
    bottom: -1.25rem;
    transition: all .3s;
    text-align: center;
    padding-top: .75rem;
    width: 4rem;

    svg {
      fill: rgba(0, 0, 0,.5);
    }

    &:hover {
      bottom: -.75rem;
      color: $purple;

      svg {
        fill: rgba(32, 18, 58, 1);;
      }
    }
  }

  .vue-inner {
    fill: #35495e;
  }

  .vue-outer {
    fill: #41b883;
  }

  .bulma {
    fill: #00D1B2;
  }
}

// .tech-page {
//   .foot {
//     .right {
//       color: rgba(255,255,255,.4);
//     }

//     .vue-inner {
//       fill: rgba(255,255,255,.2);
//     }

//     .vue-outer {
//       fill: rgba(255,255,255,.4);
//     }

//     .bulma {
//       fill: rgba(255,255,255,.4);
//     }
//   }
// }

// .tech-page .foot {
//   .left{
//     color: rgba(255,255,255,.4);
//   }
//   .home-button {
//     background: rgba(255,255,255,.1);

//     svg {
//       fill: rgba(255,255,255,.6);
//     }

//     &:hover {
//       color: $white;

//       svg {
//         fill: rgba(255,255,255,1);
//       }
//     }
//   }
// }

@media screen and (max-width: $tablet - 1px) {

  .foot {
    padding-right: .5rem;
    padding-left: .5rem;
    
    span {
      display: none;
      visibility: hidden;
    }
  }
}

@media screen and (max-width: $desktop - 1px) {

  // .home {
  //   .foot {
  //     background: $purple;
  //     margin-top: 0;

  //     .left {
  //       color: rgba(255,255,255,.4);
  //     }
  //   }
  // }
}

@media screen and (min-width: $desktop) {

  // .home {
  //   .foot {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //   }
  // }
}
</style>
