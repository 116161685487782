<template>
  <segment-wrapper category="jquery" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>So the site worked as closely to our e-commerce projects as possible, I used
        Jquery for everything script based form page loading animation and smooth scrolling
        to Frontend form validation.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JqueryOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
$('.scrollTo').on('click', function(e){
  let href = $(this).attr('href');
  $('html, body').animate({
    scrollTop: $( href ).offset().top
  },'300');
  e.preventDefault();
});

$('#tabs li').on('click', function() {
  let tab = $(this).data('tab');
  $('#tabs li').removeClass('is-active');
  $(this).addClass('is-active');
  $('#tab-content > div').removeClass('is-active');
  $('div[data-content="' + tab + '"]').addClass('is-active');
});`
    }
  },
}
</script>
