<template>
  <segment-wrapper category="vue" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <div class="video-wrapper" style="background-image: url(@/assets/images/frogg/frogg-carousel-bg.png)">
        <video loop autoplay>
          <source src="@/assets/videos/frogg-modal.mp4"  type="video/mp4">
          <source src="@/assets/videos/frogg-modal.ogg" type="video/ogg">
        </video>
      </div>

      <p>I added all the product information as data, which meant only a single item in the
        product carousel and modal were required in the code. The only thing that needed to 
        be passed through to change the information displayed was the product ID.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'VueOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
<c-modal :class="currentProduct.class" :trigger="productModalVisible" @open="productModalVisible = true" @close="productModalClose()">
  <template>
      <p class="product-title">&quot;@{{ currentProduct.name }}&quot;</p>
      <div class="product-info">
          <div class="large-image">
              <div v-if="currentProduct.spinImageCount > 0" class="spin-images">
                  <c-product-spin :images="spinImages" :keep-position="false" :reverse="true"></c-product-spin>
                  <img class="spin-icon" src="/assets/img/asset--spin-me--mobile.svg" alt="Click and drag to spin me">
              </div>
              <img v-else-if="currentProduct.mainImage != undefined" class="main-image" :src="'/assets/img/' + currentProduct.mainImage" :alt="currentProduct.name">
          </div>
              
          <div class="product-content">
              <div class="options">
                  <div v-for="(option, id) in currentProduct.options" :key="id">
                      <img :class="currentProduct.spinImageCount > 0 ? 'hidden:1t4e': ''" :src="'/assets/img/' + option.image" :alt="option.name">
                      <p class="h5">@{{ option.name }}</p>

                      <p class="price">&pound;@{{ option.price }}</p>
                      <small>@{{ option.height }}mm<br />@{{ option.weight }}g</small>
                  </div>
              </div>

              <div v-if="currentProduct.strength != undefined" class="description">
                  <p class="h5 rating"><span>Strength</span> <img :src="'/assets/img/asset--strength-'+ currentProduct.strength +'.svg'" :alt="currentProduct.strength +'/3 strength rating'">
                  <p class="mb-4">
                      <strong v-if="currentProduct.strength == '1'">Flexible</strong>
                      <strong v-else-if="currentProduct.strength == '2'">Strong</strong>
                      <strong v-else-if="currentProduct.strength == '3'">Mighty</strong>
                  </p>

                  <p>@{{ currentProduct.desc }}</p>
              </div>

              <div>
                  <a :href="currentProduct.buyOnlineLink" target="_blank"><span>Buy Online</span> <img width="10" height="17" src="/assets/img/asset-button-arrow.svg" alt="" /></a>
                  <a :href="currentProduct.buyInstoreLink"><span>Buy In-Store</span> <img width="10" height="17" src="/assets/img/asset-button-arrow.svg" alt="" /></a>
              </div>
          </div>
      </div>
  </template>
</c-modal>`
    }
  },
}
</script>
