<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/meantime/product-meta.png')" @load="imagesLoaded()" alt="Product Meta" width="519" height="286" /></p>

      <p>The designer had spruced up the unordered lists to a degree that couldn’t be styled just by targeting description lists 
        in the style sheet. These also became product meta, using repeater fields to list things like product allergies. The bullet 
        points had their fill colour set from the Theme Colour field.</p>


      <p>The alternative product types did not feature these fields or Product Theme description, so the description body was switched 
        out for the 'dd' and 'dt' lists.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyFour',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- if product.type -%}
    <dl>
        {% render 'hex-bullet' %}
        <dt>Type</dt>
        <dd>{{ product.type }}</dd>
    </dl>
{%- endif -%}
{%- if product.metafields.custom.malts.value -%}
    <dl>
        {% render 'hex-bullet' %}
        <dt>Malts</dt>
        <dd>{% assign malts = product.metafields.custom.malts.value %}{{ malts | join: ", "  }}</dd>
    </dl>
{%- endif -%}
{%- if product.metafields.custom.hops.value -%}
    <dl>
        {% render 'hex-bullet' %}
        <dt>Hops</dt>
        <dd>{% assign hops = product.metafields.custom.hops.value %}{{ hops | join: ", "  }}</dd>
    </dl>
{%- endif -%}
...
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.meantime {
  #shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #meantime-shopify-four {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>