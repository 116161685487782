<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <meantime-git-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import SegmentWrapper from '../../components/SegmentWrapper'
import Foot from '../../components/Foot'

import MeantimeGitOne from '../../components/project-segments/meantime/GitOne'

export default {
    name: 'Git',

    components: {
        NavBar, TechHeading, TechLogo, SegmentWrapper, Foot,
        MeantimeGitOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Git'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>

<style lang="scss">
#meantime-git-one {
  .text-wrap, .image-wrap {
        box-shadow: 0 0 0 3px rgba(235, 82, 2, .5);
  }
}
</style>
