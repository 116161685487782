<template>
  <div class="tech-page">
    <nav-bar/>
    <tech-heading />

    <bathe-bulma-one />
    <bathe-bulma-two />
    <txpatterns-bulma-one />

    <foot />
  </div>
</template>

<script>
import NavBar from '../../components/NavBar'
import TechHeading from '../../components/TechHeading'
import TechLogo from '../../components/TechLogo'
import Foot from '../../components/Foot'

import BatheBulmaOne from '../../components/project-segments/bathe/BulmaOne'
import BatheBulmaTwo from '../../components/project-segments/bathe/BulmaTwo'
import TxpatternsBulmaOne from '../../components/project-segments/txpatterns/BulmaOne'

export default {
    name: 'Bulma',

    components: {
        NavBar, TechHeading, TechLogo, Foot,
        BatheBulmaOne, BatheBulmaTwo, TxpatternsBulmaOne
    },

    data() {
      return {
        background: this.$route.meta.colour
      }
    },

    created() {  
      document.title = 'Bulma'
      document.head.querySelector('meta[name=robots]').content = 'noindex'
    }
  }
</script>
