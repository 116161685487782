<template>
  <div class="project-page bathe">
    <nav-bar/>
    <project-heading backgroundColour="#273b3c">
      <template v-slot:icon>
        <img :src="require('../assets/images/home/bathe-basic.svg')" alt="Bathe icon" />
      </template>

      <template v-slot:summary>
        <li>Sole Designer/Developer, working direct with customer from information stage through to build completion.</li>
        <li>First website of its type in the company, decided on structure.</li>
        <li>Matched frameworks, libraries and components from e-commerce platform to bring in coherence.</li>
        <li>Guided customer through stages, using initiative without prior training.</li>
      </template>
    </project-heading>
    
    <photoshop-one />
    <photoshop-two />
    <photoshop-three />
    <bulma-one />
    <bulma-two />
    <jquery-one />
    <jquery-two />
    <jquery-three />

    <foot />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import ProjectHeading from '../components/ProjectHeading'
import TechLogo from '../components/TechLogo'
import Foot from '../components/Foot'

import PhotoshopOne from '../components/project-segments/bathe/PhotoshopOne'
import PhotoshopTwo from '../components/project-segments/bathe/PhotoshopTwo'
import PhotoshopThree from '../components/project-segments/bathe/PhotoshopThree'
import BulmaOne from '../components/project-segments/bathe/BulmaOne'
import BulmaTwo from '../components/project-segments/bathe/BulmaTwo'
import JqueryOne from '../components/project-segments/bathe/JqueryOne'
import JqueryTwo from '../components/project-segments/bathe/JqueryTwo'
import JqueryThree from '../components/project-segments/bathe/JqueryThree'
import GitOne from '../components/project-segments/bathe/GitOne'


export default {
    name: 'Bathe',

    components: {
        NavBar, ProjectHeading, TechLogo, Foot,
        PhotoshopOne, PhotoshopTwo, PhotoshopThree, BulmaOne, BulmaTwo, JqueryOne, JqueryTwo, JqueryThree, GitOne
    },

    // mounted() {
    //   console.log(window.getComputedStyle(document.querySelector('.bathe')).getPropertyValue('background-color'))
    // },

    created() {  
      document.title = 'Bathe'
      document.head.querySelector('meta[name=description]').content = 'Brochure website for Bathroom Design and Installation company Bathe. Ben Wain UI Design and Development.'
      document.head.querySelector('meta[name=robots]').content = 'all'
    }
  }
</script>
