<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p>I used the router file to contain additional information about each section, including colours,
        associated SVG, and child components. Accessing these was sometimes straightforward where an ID
        could be passed into the function, other times, names and other assets like routes needed to be
        compared to find the right information.
      </p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper
  },

  data () {
    return {
      snippet: `
getIndex() {
  let routerRoutes = this.$router.options.routes
  let index = -1
  for(let i = 0; i < routerRoutes.length; i ++) {
    if(routerRoutes[i].path == this.route) {
      index = i
      break
    }
  }
  let techArray = this.$router.options.routes[index].meta.tech
  return techArray
},`
    }
  }
}
</script>
