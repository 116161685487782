<template>
  <segment-wrapper category="shopify" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/darkstar/catalog-pdp-v2.png')" @load="imagesLoaded()" alt="Dark Star catalog and product description" width="1182" height="1302" /></p>
      <p>For beers, the catalog pages featured a pump-clip graphic for the product, but on the product details the second image of the bottle/can 
        was to take prevalence. Up until this point I had taken for granted how flexible Javascript was with re-ordering arrays/objects.</p>

      <p>I created a second variable with the re-ordered array, and fed this into the image gallery, preserving the original incase it was needed 
        in the future.</p>
      
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'ShopifyTwo',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
{%- assign mediaReordered = '' -%}

{%- if product.media.size -%}
  {%- if isBeerCollection and product.media.size > 1 -%}
    {%- assign firstTwoMedia = product.media | slice: 0, 2 -%}
    {%- assign firstTwoMedia = firstTwoMedia | reverse -%}

    {% if product.media.size > 2 -%}
      {%- assign remainingMediaLength = product.media.size | minus: 2 -%}
      {%- assign remainingMediaLengthAsMinus = remainingMediaLength | prepend: '-' -%}
      {%- assign remainingMediaLengthAsMinus = remainingMediaLengthAsMinus | plus: 0 -%}
      {%- assign remainingMedia = product.media | slice: remainingMediaLengthAsMinus, 3 -%}

      {%- assign mediaReordered = firstTwoMedia | concat: remainingMedia -%}
    {%- else -%}
      {%- assign mediaReordered = firstTwoMedia -%}
    {%- endif -%}
  {%- else -%}
    {%- assign mediaReordered = product.media -%}
  {%- endif -%}
{%- endif -%}
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.darkstar {
  #shopify-two {
    .text-wrap {
      img {
        box-shadow: none;
        border-radius: $radius;
      }
    }
  }
}

.tech-page {
  #darkstar-shopify-two {
    .text-wrap {
      img {
        box-shadow: none;
        border-radius: $radius;
      }
    }
  }
}
</style>