<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <button v-if="currentRouteName != 'Home' && pageCount > 1" @click="checkRoute()"><img :src="require('@/assets/images/arrow-left-solid.svg')" alt="" width="17" height="20" /></button>
        <router-link :to="'/'" class="logo">benwain.info</router-link>
        <!-- <div class="nav-links" v-if="currentRouteName != 'Home'">
            <router-link :to="'/local'">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"/></svg>
                <span>Local Support</span>
            </router-link>
        </div> -->
    </nav>
</template>

<script>
export default {
  name: 'NavBar',

  props: {
      prev: {
          type: String,
      }
  },

  data () {
      return {
          lastRoute: '',
          pageCount: parseInt(sessionStorage.getItem('pageCount'))
      }
  },

  methods: {
    checkRoute() {
        this.lastRoute = localStorage.getItem('fromRoute')
        if (this.lastRoute === '/signin' && this.pageCount == 2) {
            this.$router.push({name:'Home'})
        } else if (this.lastRoute === '/signin' && this.pageCount > 2) {
            window.history.go(-2);
        } else {
            window.history.go(-1);
        }
        this.transitionName = 'slide-right'
    }
  },

  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  }
}
</script>

<style lang="scss">
.navbar {
    background: #fff;
    display: flex;
    height: 3.563rem;
    align-items: center;
    text-align: center;
    overflow: hidden;

    button {
        position: absolute;
        left: 0;
        background: transparent;
        border: 0;
        border-radius: 0;
        cursor: pointer;
        width: 3.563rem;
        aspect-ratio: 1/1;

        img {
            position: relative;
            top: .15rem;
            width: .75rem;
            opacity: .5;
        }

        &:hover, &:focus {
            background: rgba(0,0,0,.05);
            box-shadow: none;
            outline: 0;
        }
    }

    .logo {
        position: relative;
        top: .125rem;
        color: #000;
        font-family: "Hind", sans-serif;
        font-size: 1.25rem;
        margin: 0 auto;
        padding-left: 1.875rem;

        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: '';
            background-image: url('../assets/images/logo-icon.svg');
            background-size: contain;
            background-repeat: no-repeat;
            width: 1.5rem;
            height: 1.5rem;
            transform: translateY(-60%);
        }
    }

    .nav-links {
        position: absolute;
        right: 0;
        padding-right: 1.5rem;

        svg {
            position: relative;
            opacity: .5;
            top: .125rem;
        }
    }
}

@media screen and (max-width: $tablet - 1px) {
    .navbar {
        .nav-links {
            svg {
                top: .25rem;
            }

            span {
                display: none;
            }
        }
    }
}


@media screen and (min-width: $tablet) {
    
    .navbar {
        margin-top: 0;
        height: 4.563rem;

        button {
            width: 4.5625rem;
            
            img {
                width: 1.188rem;
                opacity: 1;
            }
        }

        .logo {
            font-size: 1.5rem;
            padding-left: 2.125rem;

            &::before {
                width: 1.75rem;
                height: 1.75rem;
                transform: translateY(-57%);
                
            }
        }

        .nav-links {
            svg {
                opacity: 1;
                padding-right: .5rem;
            }
        }
    }
}

@media screen and (min-width: $widescreen) {

    .navbar {
        height: 6.125rem;

        button {
            width: 6.125rem;
        }
    }
}

// @media screen and (min-width: $fullhd) {
//     .navbar {
//         a {
//             font-size: clamp(1.875rem, 1.9vw, 2.25rem);
//             padding-left: clamp(4.5rem, 4vw, 4.75rem);

//             &::before {
//                     width: clamp(3rem, 3vw, 4rem);
//                     height: clamp(3rem, 3vw, 4rem);
//             }
//         }
//     }
// }
</style>
