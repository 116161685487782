<template>
  <div class="project-segment" :class="getColour() + '-border'" :id="this.currentId">
    <router-link :to="'tech/' + category" class="top-tag" :class="getColour() + '-bg'"><div>See all</div><span>{{ category }}</span><div><img :src="require('@/assets/images/arrow-right-solid.svg')" alt="" width="10" height="12" /></div></router-link>
    
    <div class="segment-content" :class="'is-' + orientation">
      <slot name="code"></slot>
      <slot name="media"></slot>
      <div class="text-wrap">
        <slot name="text"></slot>
      </div>
    </div>

  </div>
</template>

<script>
// import gsap from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'SegmentWrapper',

  props: {
    category: {
      type: String
    },

    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data() {
    return {
      currentId: this.$options.parent.$vnode.componentOptions.tag,
      // imageHeightChecked: false
    }
  },

  methods: {
    getColour() {
      let routerRoutes = this.$router.options.routes[1].children
      let index = -1
      for(let i = 0; i < routerRoutes.length; i ++) {
        if(routerRoutes[i].name.toLowerCase() == this.category) {
          index = i
        }
      }
      let colour = this.$router.options.routes[1].children[index].meta.colour
      return colour
    },

    textAndImage(imageWrap, textWrap) {
      let textWrapHeight = textWrap !== null ? textWrap.getBoundingClientRect().height : false
      let imageWrapHeight = imageWrap.getBoundingClientRect().height
      if (textWrapHeight < imageWrapHeight) {
        textWrap.classList.add('overlay')
        imageWrap.classList.remove('overlay')
      } else {
        imageWrap.classList.add('overlay')
        textWrap.classList.remove('overlay')
      }
      if (this.orientation === 'horizontal') {
        this.addHeight(textWrapHeight, imageWrapHeight)
      }
    },

    textAndCode(codeWrap, textWrap) {
      let textWrapHeight = textWrap !== null ? textWrap.getBoundingClientRect().height : false
      let codeWrapHeight = codeWrap.getBoundingClientRect().height
      if (textWrapHeight < codeWrapHeight) {
        textWrap.classList.add('overlay')
        codeWrap.closest('.code-wrap').classList.remove('overlay')
      } else {
        codeWrap.closest('.code-wrap').classList.add('overlay')
        textWrap.classList.remove('overlay')
      }
      if (this.orientation === 'horizontal') {
        this.addHeight(textWrapHeight, codeWrapHeight)
      }
    },

    addHeight(textWrapHeight, mediaWrapHeight) {
      let codeWrap = document.querySelector('#' + this.currentId + ' .code-wrap pre')
      //console.log(this.currentId + ' txt= ' + textWrapHeight + '  med= ' + mediaWrapHeight);
      if (textWrapHeight - mediaWrapHeight > 0 && textWrapHeight - mediaWrapHeight < 50) {
        document.querySelector('#' + this.$el.id + ' .text-wrap').style.height = (textWrapHeight + 50) + 'px'
      } else if (mediaWrapHeight - textWrapHeight > 0 && mediaWrapHeight - textWrapHeight < 50) {
        if (codeWrap === null) {
          document.querySelector('#' + this.$el.id + ' .image-wrap').style.height = (mediaWrapHeight + 50) + 'px'
        } else {
          document.querySelector('#' + this.$el.id + ' .code-wrap pre').style.height = (mediaWrapHeight + 50) + 'px'
        }
      }
    }
  },

  mounted() {
    let self = this
    let textWrap = document.querySelector('#' + this.currentId + ' .text-wrap')
    let imageWrap = document.querySelector('#' + this.currentId + ' .image-wrap')
    let codeWrap = document.querySelector('#' + this.currentId + ' .code-wrap pre')

    function observerCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
        else {
          entry.target.classList.remove('visible');
        }
      })
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3
    }
    
    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const containers = document.querySelectorAll('.project-segment');
    for(let i = 0; i < containers.length; i ++) {
      observer.observe(containers[i]);
    }
    const segmentText = document.querySelectorAll('.is-vertical .text-wrap');
    for(let i = 0; i < segmentText.length; i ++) {
      observer.observe(segmentText[i]);
    }

    if (imageWrap !== null) {
      this.$root.$on('checkContainerHeight', function(){
        self.textAndImage(imageWrap, textWrap)
      })
    } else if (codeWrap !== null) {
      this.$root.$on('checkContainerHeight', function(){
        self.textAndCode(codeWrap, textWrap)
      })
    }

    // window.addEventListener('resize', function() {
    //   if (imageWrap !== null) {
    //     this.textAndImage(imageWrap, textWrap)
    //   } else if (codeWrap !== null) {
    //     this.textAndCode(codeWrap, textWrap)
    //   }
    // })
  },
}
</script>

<style lang="scss">

.project-segment {
  border-width: 0 0 0 4px;
  border-color: transparent;
  border-style: solid;

  .tech-page & {
    border-left: 0;
    overflow: hidden;
  }

  .top-tag {
    display: none;
  }

  .segment-content {
    margin-right: auto;
    margin-left: auto;
    display: flex;

    &.is-vertical {
      flex-direction: column;
    }

    &.is-horizontal {
      .text-wrap {
        flex-basis: 100%;
      }
    }

    &.is-intro {
      .text-wrap {
        border-radius: $radius;
        flex-basis: 100%;
      }
    }

    .text-wrap {
      display: flex;
      flex-direction: column;
      background: $white;
      box-shadow: 0 0 3px 0 rgba(0,0,0,.05);
      padding: 2rem 1rem;

      img {
        box-shadow: 5px 5px 15px 0 rgba(50,50,50,.2);
      }
    }

    p {
      margin-right: auto;
      margin-left: auto;
      max-width: 45.81rem;
    }
  }
}

.project-page {
  .project-segment {
    .top-tag {
      display: inline-flex;
      font-size: .75rem;
      font-weight: bold;
      color: $white;
      border-radius: 0 $radius 0 0;
      transition: max-width .3s;
      width: auto;
      //letter-spacing: .0625rem;
      //text-transform: capitalize;
      padding: .25rem;

      > div {
        display: inline-block;
        overflow: hidden;
        transition: .2s width;
        width: 0;
        white-space: nowrap;
      }

      span {
        text-transform: capitalize;
      }

      &:hover {
        > div {
          &:first-child {
          width: 2.4rem;
          }

          &:last-child {
          width: 1rem;
          transition-delay: .2s;
          }
        }

        img {
          position: relative;
          right: -.25rem;
          top: .063rem;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet - 1px) {

.project-segment {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0 0 0 $radius;

    .segment-content {
      flex-direction: column;

      &.is-vertical, &.is-horizontal {
        .image-wrap {
          border-radius: 0 $radius 0 0;
        }

        .text-wrap {
          border-radius: 0 0 $radius 0;
        }
      }

      &.is-horizontal {
        .text-wrap p:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .project-page .project-segment {
    .top-tag {
       padding-right: .4rem;
    }
  }
}

@media screen and (min-width: $tablet) {

  .project-segment {
    .top-tag {
      transform: translate(18px) rotate(90deg);
      transform-origin: top left;

      :last-child {
        transform: rotate(-45deg);
      }
    }

    .segment-content {
      padding-top: 4vw;
      padding-bottom: 4vw;

      .image-wrap img,
      .code-wrap pre code,
      .text-wrap p,
      .text-wrap img {
        opacity: 0;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-name: fade-out;
      }
    
      .text-wrap {
        position: relative;
        border-radius: .5rem;
        padding: 2rem;

        &.overlay {
          z-index: 1;
        }
      }

      &.is-horizontal {
        max-width: 67.5rem;

        .image-wrap, .text-wrap {
          align-items: flex-start;
          align-self: center;
        }

        .image-wrap, .code-wrap {
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-name: out-right;
          transform: translateX(0);
          flex-basis: 60%;
        }

        .text-wrap {
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-name: out-left;
          transform: translateX(0);
          flex-basis: 40%;
        }

        .image-wrap + .text-wrap,
        .code-wrap + .text-wrap {
        flex-basis: 50%;
        }

        .image-wrap + .text-wrap:not(.overlay) {
          padding-left: 4.5rem;
        }

        .code-wrap + .text-wrap:not(.overlay) {
          padding-left: 4.5rem;
        }
      }

      &.is-vertical {
        max-width: 66rem;

        .text-wrap {
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-name: out-up;
          align-items: flex-start;
          align-self: center;
          transform: translateY(0);
          z-index: 1;

          p {
          column-count: 2;
          column-gap: 2rem;
          }
        }
      }
    }

    &.visible .segment-content {
      &.is-horizontal {
        .code-wrap, .image-wrap {
          animation-name: in-right;
          transform: translateX(1.25rem)
        }

        .text-wrap {
          animation-name: in-left;
          transform: translateX(-1.25rem)
        }
      }

      &.is-vertical {
        .text-wrap.visible {
          animation-name: in-up;
          transform: translateY(-1.25rem)
        }
      }
    }

    &.visible {
      .image-wrap img,
      .code-wrap pre code,
      .text-wrap p,
      .text-wrap img {
        opacity: 1;
        animation-name: fade-in;
      }
    }
  }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {

  .project-segment {
    .segment-content.is-vertical {
      .text-wrap {
        margin-right: 2rem;
        margin-left: 2rem;
      }
    }
  }
}

@media screen and (min-width: $tablet) and (max-width: $fullhd - 1px) {
  .project-page {
    .project-segment {
      .segment-content {
        padding-right: 1rem;
        padding-left: 2rem;
      }
    }
  }

  .tech-page {
    .project-segment {
      .segment-content {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .project-page {
    .project-segment {
      border-width: 0 0 0 4px;
      margin: 20px;

      .top-tag {
        border-radius: $radius;
        transform: translate(11px) rotate(90deg);
        padding-right: .5rem;
        padding-left: .5rem;
      }

      .text-wrap {
        border-radius: 1rem;
      }
    }
  }
}

@media screen and (min-width: $fullhd) {

  .project-segment {
    border-width: 0 0 0 5px;

    .segment-content {
      .text-wrap {
        padding: calc(min(2.25vw, 2.75rem)) calc(min(2.5vw, 3.375rem));
        border-radius: 1rem;
      }

      p {
        max-width: calc(min(39vw, 53.125rem));
      }

      &.is-horizontal {
        max-width: calc(min(65vw, 88.563rem));

        .image-wrap + .text-wrap:not(.overlay),
        .code-wrap + .text-wrap:not(.overlay) {
          //padding-left: 6.5rem;
          padding-left: calc(min(4.5vw, 6rem));
        }
      }

      &.is-vertical {
        max-width: calc(min(63.1vw, 85.938rem));
      }
    }
  }
}
</style>
