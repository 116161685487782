<template>
  <segment-wrapper category="illustrator" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="logoGraphics" backgroundColor="#fff" />
    </template>

    <template v-slot:text>
      <p>The logo was a side project, and I based it around the initials of the software name as
        this seemed to be what Technetix had chosen to do with past projects. The lettering was
        initially written in the corporate font, then modified. The top swash of the ‘V’ and terminal 
        of the ‘S’ were matching in shape and aligned to bring a form of symmetry to the logo and 
        unison of the letters.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'IllustratorOne',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      logoGraphics: [
        {
          thumb: 'images/virtualsegmentation/logo-1.jpg',
          description: 'Initial sketches',
          width: 960,
          height: 960
        },
        {
          thumb: 'images/virtualsegmentation/logo-2.jpg',
          description: 'Final Logo',
          width: 960,
          height: 960
        }
      ]
    }
  },
}
</script>
