<template>
  <segment-wrapper category="photoshop" orientation="horizontal">
    <template v-slot:media>
      <image-layout :images="promoBlocks" backgroundColor="#fff"/>
    </template>

    <template v-slot:text>
      <p>The promo blocks below coninued with this overlaying theme with icons appearing over the
        image. If a customer selected an image, an icon, both or neither, the promo block looked
        quite different - a customisation level not seen prior to this.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import ImageLayout from '../../ImageLayout'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'PhotoshopThree',

  components: {
    ImageLayout,
    SegmentWrapper,
  },

  data () {
    return {
      promoBlocks: [
        {
          thumb: 'images/advanced4/promo-combos.png',
          description: 'Style guide provided with mockups',
          fullsize: 'images/advanced4/promo-combos.png',
          width: 560,
          height: 286
        }
      ]
    }
  },
}
</script>
