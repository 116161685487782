<template>
  <segment-wrapper category="javascript" orientation="horizontal">
    <template v-slot:code>
      <code-snippet :codeSnippet="snippet" language="javascript"></code-snippet>
    </template>

    <template v-slot:text>
      <p><img :src="require('@/assets/images/asahi/asahi-landing-screens.png')" @load="imagesLoaded()" alt="Asahi landing screens" width="431" height="581" /></p>
      
      <p>The first stage of the app was to capture the users registration details, and if a 
        query string parameter was present to show them a video landing screen.</p>
    </template>
  </segment-wrapper>
</template>

<script>
import CodeSnippet from '../../CodeSnippet'
import SegmentWrapper from '../../SegmentWrapper'

export default {
  name: 'JavascriptOne',

  components: {
    CodeSnippet,
    SegmentWrapper,
  },

  data () {
    return {
      snippet: `
created() {
  let self = this;
  let pathname = window.location.pathname;

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
      
  if (pathname == '/') {
    if (params.video) {
      self.stage = 'video';
    }
  }
`
    }
  },

  methods: {
    imagesLoaded() {
      this.$root.$emit('checkContainerHeight')
    }
  }
}
</script>

<style lang="scss">
.asahi {
  #javascript-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}

.tech-page {
  #asahi-javascript-one {
    .text-wrap {
      img {
        box-shadow: none;
      }
    }
  }
}
</style>
